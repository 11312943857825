import { Fragment, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionActions, AccordionSummary, AccordionDetails } from "@mui/material";
import { ShareEmailModal, VAButton } from "../../components";
import { ShareIcon } from "../../icons/Share.icon";
import { AppState } from "../../redux/store";
import { useSelector } from "react-redux";
import VAAlert from "../../components/Alert/Alert";
import { AlertType } from "../../shared/constants/AppConst";
interface AccordianProps {
  data: any;
}
export default function VaccineRecordAccordian({ data }: AccordianProps) {
  const { userData } = useSelector<AppState, AppState["Login"]>(({ Login }) => Login);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);
  const [shareFormData, setShareFormData] = useState<any>({});
  const onClickShare = async (shareFormDataObj: any) => {
    shareFormDataObj.patient_email = userData.email;
    shareFormDataObj.patient_name = userData.name;
    setShareFormData(shareFormDataObj);
    setOpen(true);
  };

  return (
    <Fragment>
      {data?.map((vaccineRecord: any, idx: number) => {
        return (
          <Accordion key={idx}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${idx}-content`} id={`panel${idx}-header`}>
              <b>{vaccineRecord.vaccine}</b>
            </AccordionSummary>
            {vaccineRecord.verified ? (
              <Fragment>
                <AccordionDetails>
                  <div className="mt-[10px]">
                    <p className="text-[#888A8C]">
                      Manufacturer: <span className="text-[#000000]">{vaccineRecord.manufacturer}</span>
                    </p>
                    <p className="text-[#888A8C]">
                      Lot Number: <span className="text-[#000000]">{vaccineRecord.lot_no}</span>
                    </p>
                    <p className="text-[#888A8C]">
                      NDC: <span className="text-[#000000]">{vaccineRecord.ndc}</span>
                    </p>
                    <p className="text-[#888A8C]">
                      Verified By: <span className="text-[#000000]">{`${vaccineRecord.verifier_name}, ${vaccineRecord.biz_branch_address}`}</span>
                    </p>
                    <p className="text-[#888A8C]">
                      Date Exp: <span className="text-[#000000]">{vaccineRecord.date_exp}</span>
                    </p>
                    <p className="text-[#888A8C]">
                      Date Administered: <span className="text-[#000000]">{vaccineRecord.date_administered}</span>
                    </p>
                    <p className="text-[#888A8C]">
                      Notes: <span className="text-[#000000]">{vaccineRecord.verify_note}</span>
                    </p>
                  </div>
                </AccordionDetails>
                <AccordionActions>
                  <VAButton size="small" dark text="Share" icon={<ShareIcon />} onClick={() => onClickShare(vaccineRecord)} />
                </AccordionActions>
              </Fragment>
            ) : null}
          </Accordion>
        );
      })}
      <ShareEmailModal open={open} setOpen={setOpen} shareClick={onClickShare} data={shareFormData} setAlertMessage={setAlertMessage} />
      {alertMessage !== null ? <VAAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} /> : null}
    </Fragment>
  );
}
