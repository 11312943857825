import { ReactElement } from "react";

export const BookAppoinment = (): ReactElement => {
  return (
    <svg width="39" height="41" viewBox="0 0 39 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.7188 34.1668H3.10041C1.59086 34.1668 0.367188 32.9431 0.367188 31.4336V28.7002H21.3442L18.7188 34.1668Z" fill="#D3DCFB" />
      <path d="M35.9031 12.2997V23.7827V31.4329H3.10041C1.59086 31.4329 0.367188 30.2092 0.367188 28.6996V12.2997L19.5019 5.46631L35.9031 12.2997Z" fill="#EBF5FC" />
      <path
        d="M5.19425 17.767H3.74219C3.3884 17.767 3.10156 17.4802 3.10156 17.1264V15.6743C3.10156 15.3205 3.3884 15.0337 3.74219 15.0337H5.19425C5.54803 15.0337 5.83487 15.3205 5.83487 15.6743V17.1264C5.83487 17.4802 5.54803 17.767 5.19425 17.767Z"
        fill="#3C58A0"
      />
      <path
        d="M10.661 17.767H9.20898C8.8552 17.767 8.56836 17.4802 8.56836 17.1264V15.6743C8.56836 15.3205 8.8552 15.0337 9.20898 15.0337H10.661C11.0148 15.0337 11.3017 15.3205 11.3017 15.6743V17.1264C11.3017 17.4802 11.0148 17.767 10.661 17.767Z"
        fill="#3C58A0"
      />
      <path
        d="M16.1278 17.767H14.6758C14.322 17.767 14.0352 17.4802 14.0352 17.1264V15.6743C14.0352 15.3205 14.322 15.0337 14.6758 15.0337H16.1278C16.4816 15.0337 16.7685 15.3205 16.7685 15.6743V17.1264C16.7685 17.4802 16.4817 17.767 16.1278 17.767Z"
        fill="#3C58A0"
      />
      <path
        d="M21.5946 17.767H20.1426C19.7888 17.767 19.502 17.4802 19.502 17.1264V15.6743C19.502 15.3205 19.7888 15.0337 20.1426 15.0337H21.5946C21.9484 15.0337 22.2353 15.3205 22.2353 15.6743V17.1264C22.2353 17.4802 21.9484 17.767 21.5946 17.767Z"
        fill="#3C58A0"
      />
      <path
        d="M27.0595 17.767H25.6074C25.2536 17.767 24.9668 17.4802 24.9668 17.1264V15.6743C24.9668 15.3205 25.2536 15.0337 25.6074 15.0337H27.0595C27.4133 15.0337 27.7001 15.3205 27.7001 15.6743V17.1264C27.7001 17.4802 27.4133 17.767 27.0595 17.767Z"
        fill="#3C58A0"
      />
      <path
        d="M32.5263 17.767H31.0742C30.7204 17.767 30.4336 17.4802 30.4336 17.1264V15.6743C30.4336 15.3205 30.7204 15.0337 31.0742 15.0337H32.5263C32.8801 15.0337 33.1669 15.3205 33.1669 15.6743V17.1264C33.1669 17.4802 32.8801 17.767 32.5263 17.767Z"
        fill="#3C58A0"
      />
      <path
        d="M5.19425 23.2333H3.74219C3.3884 23.2333 3.10156 22.9465 3.10156 22.5927V21.1406C3.10156 20.7868 3.3884 20.5 3.74219 20.5H5.19425C5.54803 20.5 5.83487 20.7868 5.83487 21.1406V22.5927C5.83487 22.9465 5.54803 23.2333 5.19425 23.2333Z"
        fill="#3C58A0"
      />
      <path
        d="M10.661 23.2333H9.20898C8.8552 23.2333 8.56836 22.9465 8.56836 22.5927V21.1406C8.56836 20.7868 8.8552 20.5 9.20898 20.5H10.661C11.0148 20.5 11.3017 20.7868 11.3017 21.1406V22.5927C11.3017 22.9465 11.0148 23.2333 10.661 23.2333Z"
        fill="#3C58A0"
      />
      <path
        d="M16.1278 23.2333H14.6758C14.322 23.2333 14.0352 22.9465 14.0352 22.5927V21.1406C14.0352 20.7868 14.322 20.5 14.6758 20.5H16.1278C16.4816 20.5 16.7685 20.7868 16.7685 21.1406V22.5927C16.7685 22.9465 16.4817 23.2333 16.1278 23.2333Z"
        fill="#3C58A0"
      />
      <path
        d="M22.2353 23.2333V21.1833C22.2353 20.8059 21.9294 20.5 21.552 20.5H20.1853C19.8079 20.5 19.502 20.8059 19.502 21.1833V22.55C19.502 22.9274 19.8079 23.2333 20.1853 23.2333H22.2353Z"
        fill="#3C58A0"
      />
      <path
        d="M5.19425 28.7006H3.74219C3.3884 28.7006 3.10156 28.4138 3.10156 28.06V26.6079C3.10156 26.2541 3.3884 25.9673 3.74219 25.9673H5.19425C5.54803 25.9673 5.83487 26.2541 5.83487 26.6079V28.06C5.83487 28.4138 5.54803 28.7006 5.19425 28.7006Z"
        fill="#3C58A0"
      />
      <path
        d="M10.661 28.7006H9.20898C8.8552 28.7006 8.56836 28.4138 8.56836 28.06V26.6079C8.56836 26.2541 8.8552 25.9673 9.20898 25.9673H10.661C11.0148 25.9673 11.3017 26.2541 11.3017 26.6079V28.06C11.3017 28.4138 11.0148 28.7006 10.661 28.7006Z"
        fill="#3C58A0"
      />
      <path
        d="M16.1278 28.7006H14.6758C14.322 28.7006 14.0352 28.4138 14.0352 28.06V26.6079C14.0352 26.2541 14.322 25.9673 14.6758 25.9673H16.1278C16.4816 25.9673 16.7685 26.2541 16.7685 26.6079V28.06C16.7685 28.4138 16.4817 28.7006 16.1278 28.7006Z"
        fill="#3C58A0"
      />
      <path
        d="M32.5069 15.0332H31.0936C31.008 15.0332 30.9266 15.05 30.8516 15.0796C31.0962 15.1761 31.2695 15.4141 31.2695 15.6931V17.1065C31.2695 17.3855 31.0961 17.6235 30.8516 17.7201C30.9266 17.7497 31.008 17.7665 31.0936 17.7665H32.5069C32.8715 17.7665 33.1669 17.471 33.1669 17.1066V15.6932C33.1669 15.3287 32.8714 15.0332 32.5069 15.0332V15.0332Z"
        fill="#2A428C"
      />
      <path
        d="M27.0401 15.0332H25.6268C25.5412 15.0332 25.4598 15.05 25.3848 15.0796C25.6294 15.1761 25.8027 15.4141 25.8027 15.6931V17.1065C25.8027 17.3855 25.6293 17.6235 25.3848 17.7201C25.4598 17.7497 25.5412 17.7665 25.6268 17.7665H27.0401C27.4047 17.7665 27.7001 17.471 27.7001 17.1066V15.6932C27.7001 15.3287 27.4047 15.0332 27.0401 15.0332V15.0332Z"
        fill="#2A428C"
      />
      <path
        d="M21.5753 15.0332H20.1619C20.0764 15.0332 19.995 15.05 19.9199 15.0796C20.1646 15.1761 20.3379 15.4141 20.3379 15.6931V17.1065C20.3379 17.3855 20.1646 17.6235 19.9199 17.7201C19.995 17.7497 20.0764 17.7665 20.1619 17.7665H21.5753C21.9398 17.7665 22.2353 17.471 22.2353 17.1066V15.6932C22.2352 15.3287 21.9397 15.0332 21.5753 15.0332V15.0332Z"
        fill="#2A428C"
      />
      <path
        d="M16.1085 15.0332H14.6951C14.6096 15.0332 14.5282 15.05 14.4531 15.0796C14.6978 15.1761 14.8711 15.4141 14.8711 15.6931V17.1065C14.8711 17.3855 14.6978 17.6235 14.4531 17.7201C14.5282 17.7497 14.6096 17.7665 14.6951 17.7665H16.1085C16.473 17.7665 16.7685 17.471 16.7685 17.1066V15.6932C16.7684 15.3287 16.473 15.0332 16.1085 15.0332V15.0332Z"
        fill="#2A428C"
      />
      <path
        d="M10.6417 15.0332H9.22832C9.1428 15.0332 9.06136 15.05 8.98633 15.0796C9.23097 15.1761 9.40426 15.4141 9.40426 15.6931V17.1065C9.40426 17.3855 9.23089 17.6235 8.98633 17.7201C9.06136 17.7497 9.1428 17.7665 9.22832 17.7665H10.6417C11.0062 17.7665 11.3017 17.471 11.3017 17.1066V15.6932C11.3017 15.3287 11.0062 15.0332 10.6417 15.0332V15.0332Z"
        fill="#2A428C"
      />
      <path
        d="M5.17491 15.0332H3.76153C3.676 15.0332 3.59456 15.05 3.51953 15.0796C3.76417 15.1761 3.93754 15.4141 3.93754 15.6931V17.1065C3.93754 17.3855 3.76417 17.6235 3.51953 17.7201C3.59456 17.7497 3.676 17.7665 3.76153 17.7665H5.17491C5.53942 17.7665 5.83491 17.471 5.83491 17.1066V15.6932C5.83483 15.3287 5.53934 15.0332 5.17491 15.0332V15.0332Z"
        fill="#2A428C"
      />
      <path
        d="M5.17491 20.5H3.76153C3.676 20.5 3.59456 20.5168 3.51953 20.5464C3.76417 20.6429 3.93754 20.8809 3.93754 21.1599V22.5734C3.93754 22.8524 3.76417 23.0904 3.51953 23.1869C3.59456 23.2166 3.676 23.2334 3.76153 23.2334H5.17491C5.53942 23.2334 5.83491 22.9379 5.83491 22.5735V21.16C5.83483 20.7955 5.53934 20.5 5.17491 20.5V20.5Z"
        fill="#2A428C"
      />
      <path
        d="M5.17491 25.9668H3.76153C3.676 25.9668 3.59456 25.9836 3.51953 26.0132C3.76417 26.1097 3.93754 26.3477 3.93754 26.6267V28.0402C3.93754 28.3192 3.76417 28.5572 3.51953 28.6537C3.59456 28.6834 3.676 28.7002 3.76153 28.7002H5.17491C5.53942 28.7002 5.83491 28.4047 5.83491 28.0403V26.6268C5.83483 26.2622 5.53934 25.9668 5.17491 25.9668V25.9668Z"
        fill="#2A428C"
      />
      <path
        d="M10.6417 20.5H9.22832C9.1428 20.5 9.06136 20.5168 8.98633 20.5464C9.23097 20.6429 9.40426 20.8809 9.40426 21.1599V22.5734C9.40426 22.8524 9.23089 23.0904 8.98633 23.1869C9.06136 23.2166 9.1428 23.2334 9.22832 23.2334H10.6417C11.0062 23.2334 11.3017 22.9379 11.3017 22.5735V21.16C11.3017 20.7955 11.0062 20.5 10.6417 20.5V20.5Z"
        fill="#2A428C"
      />
      <path
        d="M10.6417 25.9668H9.22832C9.1428 25.9668 9.06136 25.9836 8.98633 26.0132C9.23097 26.1097 9.40426 26.3477 9.40426 26.6267V28.0402C9.40426 28.3192 9.23089 28.5572 8.98633 28.6537C9.06136 28.6834 9.1428 28.7002 9.22832 28.7002H10.6417C11.0062 28.7002 11.3017 28.4047 11.3017 28.0403V26.6268C11.3017 26.2622 11.0062 25.9668 10.6417 25.9668V25.9668Z"
        fill="#2A428C"
      />
      <path
        d="M16.1085 20.5H14.6951C14.6096 20.5 14.5282 20.5168 14.4531 20.5464C14.6978 20.6429 14.8711 20.8809 14.8711 21.1599V22.5734C14.8711 22.8524 14.6978 23.0904 14.4531 23.1869C14.5282 23.2166 14.6096 23.2334 14.6951 23.2334H16.1085C16.473 23.2334 16.7685 22.9379 16.7685 22.5735V21.16C16.7684 20.7955 16.473 20.5 16.1085 20.5V20.5Z"
        fill="#2A428C"
      />
      <path
        d="M22.2353 23.2333V21.1599C22.2353 20.7954 21.9398 20.5 21.5753 20.5H20.1619C20.0764 20.5 19.995 20.5168 19.9199 20.5464C20.1646 20.6429 20.3379 20.8809 20.3379 21.1599V22.5734C20.3379 22.8524 20.1646 23.0904 19.9199 23.1869C19.995 23.2166 20.0764 23.2334 20.1619 23.2334H22.2353V23.2333Z"
        fill="#2A428C"
      />
      <path
        d="M16.1085 25.9668H14.6951C14.6096 25.9668 14.5282 25.9836 14.4531 26.0132C14.6978 26.1097 14.8711 26.3477 14.8711 26.6267V28.0402C14.8711 28.3192 14.6978 28.5572 14.4531 28.6537C14.5282 28.6834 14.6096 28.7002 14.6951 28.7002H16.1085C16.473 28.7002 16.7685 28.4047 16.7685 28.0403V26.6268C16.7684 26.2622 16.473 25.9668 16.1085 25.9668V25.9668Z"
        fill="#2A428C"
      />
      <path d="M34.0039 11.5088V23.7829V31.4332H35.9026V23.7829V12.2999L34.0039 11.5088Z" fill="#D3DCFB" />
      <path d="M35.9031 12.3V5.46662C35.9031 3.95707 34.6794 2.7334 33.1699 2.7334H3.10041C1.59086 2.7334 0.367188 3.95707 0.367188 5.46662V12.3H35.9031Z" fill="#FFB547" />
      <path
        d="M8.97981 6.08551H7.20071C6.85894 6.08551 6.58203 5.80852 6.58203 5.46683C6.58203 5.12513 6.85902 4.84814 7.20071 4.84814H8.97981C9.32158 4.84814 9.59849 5.12513 9.59849 5.46683C9.59849 5.80852 9.3215 6.08551 8.97981 6.08551V6.08551Z"
        fill="#F2B51D"
      />
      <path
        d="M13.3509 6.08551H11.5718C11.23 6.08551 10.9531 5.80852 10.9531 5.46683C10.9531 5.12513 11.2301 4.84814 11.5718 4.84814H13.3509C13.6927 4.84814 13.9696 5.12513 13.9696 5.46683C13.9696 5.80852 13.6927 6.08551 13.3509 6.08551V6.08551Z"
        fill="#F2B51D"
      />
      <path
        d="M24.6967 6.08551H22.9175C22.5757 6.08551 22.2988 5.80852 22.2988 5.46683C22.2988 5.12513 22.5758 4.84814 22.9175 4.84814H24.6967C25.0385 4.84814 25.3154 5.12513 25.3154 5.46683C25.3154 5.80852 25.0385 6.08551 24.6967 6.08551Z"
        fill="#F2B51D"
      />
      <path
        d="M29.0678 6.08551H27.2886C26.9468 6.08551 26.6699 5.80852 26.6699 5.46683C26.6699 5.12513 26.9469 4.84814 27.2886 4.84814H29.0678C29.4096 4.84814 29.6865 5.12513 29.6865 5.46683C29.6865 5.80852 29.4095 6.08551 29.0678 6.08551Z"
        fill="#F2B51D"
      />
      <path
        d="M11.983 5.125C11.983 6.06848 11.2182 6.83331 10.2747 6.83331C9.33123 6.83331 8.56641 6.06848 8.56641 5.125V1.70831C8.56633 0.764826 9.33115 0 10.2746 0C11.2181 0 11.9829 0.764826 11.9829 1.70831V5.125H11.983Z"
        fill="#EBF5FC"
      />
      <path
        d="M27.7019 5.125C27.7019 6.06848 26.937 6.83331 25.9935 6.83331C25.0501 6.83331 24.2852 6.06848 24.2852 5.125V1.70831C24.2852 0.764826 25.05 0 25.9935 0C26.9369 0 27.7018 0.764826 27.7018 1.70831V5.125H27.7019Z"
        fill="#EBF5FC"
      />
      <path
        d="M25.9927 0C25.642 0 25.3162 0.106023 25.0449 0.28732C25.5034 0.593699 25.8055 1.11557 25.8055 1.70839V5.125C25.8055 5.71774 25.5034 6.23969 25.0449 6.54607C25.3161 6.72728 25.642 6.83339 25.9927 6.83339C26.9362 6.83339 27.701 6.06856 27.701 5.12508V1.70831C27.7011 0.764826 26.9363 0 25.9927 0V0Z"
        fill="#D3DCFB"
      />
      <path
        d="M10.2759 0C9.92519 0 9.59943 0.106023 9.32812 0.28732C9.78657 0.593699 10.0887 1.11557 10.0887 1.70839V5.125C10.0887 5.71774 9.78657 6.23969 9.32812 6.54607C9.59935 6.72728 9.92519 6.83339 10.2759 6.83339C11.2194 6.83339 11.9842 6.06856 11.9842 5.12508V1.70831C11.9843 0.764826 11.2195 0 10.2759 0V0Z"
        fill="#D3DCFB"
      />
      <path d="M33.1696 2.7334H31.2715C32.781 2.7334 34.0047 3.95707 34.0047 5.46663V12.3001H35.9027V5.46671C35.9028 3.95715 34.6791 2.7334 33.1696 2.7334V2.7334Z" fill="#C77700" />
      <path d="M0.367188 8.94775H35.9032V10.1852H0.367188V8.94775Z" fill="#C77700" />
      <path
        d="M28.3848 41C34.0457 41 38.6348 36.4109 38.6348 30.75C38.6348 25.0891 34.0457 20.5 28.3848 20.5C22.7238 20.5 18.1348 25.0891 18.1348 30.75C18.1348 36.4109 22.7238 41 28.3848 41Z"
        fill="#4FABF7"
      />
      <path
        d="M28.3858 38.2668C32.5372 38.2668 35.9025 34.9014 35.9025 30.7501C35.9025 26.5987 32.5372 23.2334 28.3858 23.2334C24.2345 23.2334 20.8691 26.5987 20.8691 30.7501C20.8691 34.9014 24.2345 38.2668 28.3858 38.2668Z"
        fill="#EBF5FC"
      />
      <path
        d="M28.3843 30.0017C28.0425 30.0017 27.7656 29.7247 27.7656 29.383V25.9663C27.7656 25.6246 28.0426 25.3477 28.3843 25.3477C28.7261 25.3477 29.003 25.6246 29.003 25.9663V29.383C29.003 29.7247 28.726 30.0017 28.3843 30.0017Z"
        fill="#FC476E"
      />
      <path
        d="M31.8016 31.3687H29.7515C29.4097 31.3687 29.1328 31.0917 29.1328 30.75C29.1328 30.4083 29.4098 30.1313 29.7515 30.1313H31.8016C32.1433 30.1313 32.4203 30.4083 32.4203 30.75C32.4203 31.0917 32.1433 31.3687 31.8016 31.3687Z"
        fill="#FC476E"
      />
      <path
        d="M28.3863 20.5C28.0664 20.5 27.75 20.5155 27.4376 20.5441C32.6536 21.0229 36.7388 25.409 36.7388 30.75C36.7388 36.091 32.6535 40.4771 27.4375 40.9559C27.75 40.9845 28.0663 41 28.3862 41C34.0471 41 38.6362 36.4109 38.6362 30.75C38.6362 25.0891 34.0471 20.5 28.3863 20.5Z"
        fill="#1886EA"
      />
      <path
        d="M28.3855 23.2334C28.0644 23.2334 27.748 23.2537 27.4375 23.2927C31.1413 23.7589 34.0062 26.9199 34.0062 30.7501C34.0062 34.5803 31.1413 37.7413 27.4375 38.2075C27.748 38.2465 28.0644 38.2668 28.3855 38.2668C32.5369 38.2668 35.9022 34.9014 35.9022 30.7501C35.9022 26.5988 32.5369 23.2334 28.3855 23.2334V23.2334Z"
        fill="#D3DCFB"
      />
      <path
        d="M28.3843 32.1167C29.1391 32.1167 29.751 31.5048 29.751 30.75C29.751 29.9952 29.1391 29.3833 28.3843 29.3833C27.6295 29.3833 27.0176 29.9952 27.0176 30.75C27.0176 31.5048 27.6295 32.1167 28.3843 32.1167Z"
        fill="#FFDD40"
      />
    </svg>
  );
};
