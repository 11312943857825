import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./redux/store";

import "./App.scss";
import {
  ChangePassword,
  Home,
  Notification,
  Profile,
  VaccineRecords,
  ScheduleAppointment,
  Login,
  Welcome,
  CreateProfile,
  SignUp,
  ForgotPassword,
  ResetPassword,
  TermsCondition,
  CreateProfileWelcome,
  FinalWelcome,
  TestRecords,
} from "./pages";
import { Schedule } from "./pages/Schedule";
import { FillDetails } from "./pages/Schedule/FillDetails.page";
import { FillTestDetails } from "./pages/ScheduleTest/FillTestDetails.page";
import { ScheduleTest, ScheduleTestAppointment } from "./pages/ScheduleTest";

const store = configureStore();

function App() {
  return (
    <div>
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/terms-condition" element={<TermsCondition />} />
            <Route path="/create-profile-welcome" element={<CreateProfileWelcome />} />
            <Route path="/create-profile" element={<CreateProfile />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/final-welcome" element={<FinalWelcome />} />
            <Route path="/home" element={<Home />} />
            <Route path="/ScheduleAppointment" element={<ScheduleAppointment />} />
            <Route path="/schedule-vaccines" element={<Schedule />} />
            <Route path="/schedule-tests" element={<ScheduleTest />} />
            <Route path="/scheduletestappointment" element={<ScheduleTestAppointment />} />
            <Route path="/fill-details" element={<FillDetails />} />
            <Route path="/fill-test-details" element={<FillTestDetails />} />
            <Route path="/vaccine-records" element={<VaccineRecords />} />
            <Route path="/test-records" element={<TestRecords />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="/change-password" element={<ChangePassword />} />
          </Routes>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
