import { ReactElement, useState } from "react";
import { Link } from "react-router-dom";
import { checkEmail } from "../../redux/actionCreators";
import { ForgotPasswordForm } from "../../sections/ForgotPassword";
import { useFormFieldsHandleChange } from "../../utils";
import emailjs from "@emailjs/browser";

export const ForgotPassword = (): ReactElement => {
  //State and Handlers
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [forgotPassInfo] = useState<any>({ email: "", name: "" });
  const [forgotPassInfoInputs, handleforgotPassInfoChange] = useFormFieldsHandleChange(forgotPassInfo);
  const [errorsforgotPassInfo, setErrorsforgotPassInfo] = useState({
    email: { error: false, message: "" },
  });

  const validateErrorforgotPassInfo = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!e.target.value) {
      setErrorsforgotPassInfo({ ...errorsforgotPassInfo, [e.target.name]: { error: true, message: "" } });
    } else {
      setErrorsforgotPassInfo({ ...errorsforgotPassInfo, [e.target.name]: { error: false, message: "" } });
    }
  };

  const validateFormforgotPassInfo = () => {
    let formIsValid = true;
    let localerrorsforgotPassInfo = errorsforgotPassInfo;

    if (forgotPassInfoInputs.email === "") {
      formIsValid = false;
      localerrorsforgotPassInfo.email = { error: true, message: "Email is Required!" };
    } else if (!emailPattern.test(forgotPassInfoInputs.email)) {
      formIsValid = false;
      localerrorsforgotPassInfo.email = { error: true, message: "Invalid Email!" };
    }

    setErrorsforgotPassInfo({ ...errorsforgotPassInfo, ...localerrorsforgotPassInfo });
    return formIsValid;
  };

  const sendEmail = () => {
    const values = {
      fullName: forgotPassInfoInputs.name,
      email: forgotPassInfoInputs.email,
      role: "",
      message: `Click the link below to reset your password.`,
    };
    emailjs.send("service_q1sj92a", "template_lh72jpi", values, "bPnkTyXgeUXVCp7PY");
  };

  const onSubmit = async () => {
    if (!validateFormforgotPassInfo) return;
    //check if email exists
    let res = await checkEmail(forgotPassInfoInputs.email);
    if (res.message === "Email Already Exist") {
      //send email
      forgotPassInfoInputs.name = res.data.first_name;
      sendEmail();
      alert("Password Reset Link sent to provided email.");
      return;
    } else {
      setErrorsforgotPassInfo({ ...errorsforgotPassInfo, email: { error: true, message: "Email address is not registered!" } });
    }
  };

  return (
    <div className="flex gap-[40px] w-full">
      <div className="hidden md:block md:w-[55%] md:relative ">
        <div className="w-full h-[100vh]">
          <img src={`${process.env.PUBLIC_URL}/imgs/undraw_forgot_password.png`} alt="Vaccifi" className="object-cover fixed w-[55%] h-full" />
        </div>
      </div>
      <div className="w-full p-[20px] flex justify-center items-center md:w-[45%]">
        <div className="w-[380px]">
          <div>
            <img src={`${process.env.PUBLIC_URL}/imgs/va-logo-with-text.svg`} alt="vaccine text" />
            <h2 className="text-[25px] font-[600] text-[#0F5175]">Forgot Password</h2>
          </div>
          <div className="mt-[30px]">
            <ForgotPasswordForm
              inputs={forgotPassInfoInputs}
              errorsforgotPassInfo={errorsforgotPassInfo}
              validateErrorforgotPassInfo={validateErrorforgotPassInfo}
              onChange={handleforgotPassInfoChange}
              onSubmit={onSubmit}
            />
          </div>
          <div style={{ textAlign: "center" }} className="mt-[40px]">
            <span className="text-[16px] font-[400] text-[#888A8C]">
              <Link to="/login" className="text-[16px] text-[#0F5175] font-[600px]">
                Back to Sign In
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
