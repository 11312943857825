import jwtAxios from "../../services/auth/jwt-auth";

export const getShareVaccineRecords = async (data: any) => {
  try {
    const res = await jwtAxios.post("/vaccinerecord/getvaccinesharerecords", data);
    return res.data;
  } catch (error) {
    console.error("Error in getShareVaccineRecords:", error);
  }
};

export const getShareTestRecords = async (data: any) => {
  try {
    const res = await jwtAxios.post("/vaccinerecord/gettestsharerecords", data);
    return res.data;
  } catch (error) {
    console.error("Error in getShareVaccineRecords:", error);
  }
};

export const shareRecord = async (data: any) => {
  try {
    const res = await jwtAxios.post("/vaccinerecord/sharerecord", data);
    return res.data;
  } catch (error) {
    console.error("Error in getShareVaccineRecords:", error);
  }
};
