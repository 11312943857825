import { AppActions } from "../../types";
import { GET_CATEGORIES, GET_VACCINES } from "../../types/actions/ScheduleVaccine.action";
import { categoriesModel, vaccinesModel } from "../../types/models/scheduleVaccine";

const initialState: {
  categories: categoriesModel[];
  vaccines: vaccinesModel[];
} = {
  categories: [],
  vaccines: [],
};
const ScheduleVaccine = (state = initialState, action: AppActions) => {
  switch (action.type) {
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case GET_VACCINES:
      return {
        ...state,
        vaccines: action.payload,
      };
    default:
      return state;
  }
};
export default ScheduleVaccine;
