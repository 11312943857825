import { ReactElement } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Multiselect from "multiselect-react-dropdown";

interface Options {
  label?: string;
  biz_branch_name?: string;
  biz_branch_address?: string;
  value?: string;
}

interface SelectProps {
  value?: string;
  label?: string;
  options?: Options[];
  heading?: string;
  name?: string;
  validate?: { error: boolean; message: string };
  required?: true;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onSubmit?: (value: string) => void;
}

const CssTextField = styled(TextField)({
  "&": {
    width: "100%",
  },
  "& secondaryLabel": {
    color: "gray",
  },
  "& label.Mui-focused": {
    color: "#888A8C",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#CDCFD2",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    "& fieldset": {
      borderColor: "#CDCFD2",
    },
    "&:hover fieldset": {
      borderColor: "#CDCFD2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#CDCFD2",
    },
  },
});

export const FromSelect = ({ label, options, value, heading, name, validate, required, onChange, onBlur, onSubmit }: SelectProps): ReactElement => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
    if (onSubmit) {
      onSubmit(event.target.value);
    }
  };
  return (
    <div>
      {heading ? (
        <div className="flex flex-col gap-[12px]">
          <h2 className="text-[16px] font-[600] text-[#1F2124]">{heading}</h2>
          {heading === "Vaccine" ? (
            <CssTextField
              id="outlined-select-currency"
              name={name}
              select
              label={label}
              value={value}
              required={required}
              error={validate?.error}
              helperText={validate?.message}
              onChange={handleChange}
              onBlur={onBlur}
            >
              {options?.map((option) => (
                <>
                  <div className="flex">
                    <Multiselect />
                    {/* <Checkbox /> */}
                    {/* <MenuItem key={option.value} value={option.value}>
                      {option.label} 
                    </MenuItem> */}
                  </div>
                </>
              ))}
            </CssTextField>
          ) : (
            <CssTextField
              id="outlined-select-currency"
              name={name}
              select
              label={label}
              value={value}
              required={required}
              error={validate?.error}
              helperText={validate?.message}
              onChange={handleChange}
              onBlur={onBlur}
            >
              {options?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </CssTextField>
          )}
        </div>
      ) : (
        <CssTextField
          id="outlined-select-currency"
          name={name}
          select
          label={label}
          value={value}
          required={required}
          error={validate?.error}
          helperText={validate?.message}
          onChange={handleChange}
          onBlur={onBlur}
        >
          {options?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {label === "Select Location" ? (
                <>
                  {option.biz_branch_name}
                  <br />
                  {option.biz_branch_address}
                </>
              ) : (
                option.label
              )}
            </MenuItem>
          ))}
        </CssTextField>
      )}
    </div>
  );
};

// import { ReactElement } from "react";
// import TextField from "@mui/material/TextField";
// import MenuItem from "@mui/material/MenuItem";
// import { styled } from "@mui/material/styles";

// interface Options {
//   label?: string;
//   value?: string;
// }

// interface SelectProps {
//   name: string;
//   label: string;
//   value: string;
//   error: boolean;
//   required?: true;
//   onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
//   options: Options[];
//   onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
// }

// // setValue: (e: string) => void;
// // value: string;
// // label?: string;
// // options?: Options[];
// // }

// const CssTextField = styled(TextField)({
//   "&": {
//     width: "100%",
//   },
//   "& label.Mui-focused": {
//     color: "#888A8C",
//   },
//   "& .MuiInput-underline:after": {
//     borderBottomColor: "#CDCFD2",
//   },
//   "& .MuiOutlinedInput-root": {
//     borderRadius: "8px",
//     "& fieldset": {
//       borderColor: "#CDCFD2",
//     },
//     "&:hover fieldset": {
//       borderColor: "#CDCFD2",
//     },
//     "&.Mui-focused fieldset": {
//       borderColor: "#CDCFD2",
//     },
//   },
// });

// export const FromSelect = ({ name, label, value, options, error, required, onChange, onBlur }: SelectProps): ReactElement => {
//   // const handleChange = (e: any) => {
//   //   setValue(e.target.value);
//   // };
//   return (
//     <div>
//       <CssTextField name={name} select label={label} value={value} required={required} error={error} onChange={onChange} onBlur={onBlur}>
//         {options?.map((option) => (
//           <MenuItem key={option.value} value={option.value}>
//             {option.label}
//           </MenuItem>
//         ))}
//       </CssTextField>
//     </div>
//   );
// };
