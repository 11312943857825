import { eachDayOfInterval, format, parseISO } from "date-fns";
import { nanoid } from "nanoid";

export const getMatchMonthAndYear = (monthToMatch: any, yearToMatch: any, events: any): any => {
  if (!events.length) return [];

  const allMatchedEvents = events.filter((event: { startAt: string }) => {
    const isoDate = parseISO(event.startAt);
    const monthInString = format(isoDate, "LLLL"); // December
    const year = isoDate.getFullYear();
    return monthToMatch === monthInString && year === yearToMatch;
  });
  return allMatchedEvents;
};

export const getEventsByDayNumber = (currentDay: number, allEvents: any[]) => {
  if (!allEvents.length) return [];

  allEvents = allEvents.filter((event: { date: string; events: any }) => {
    const day = new Date(event.date).getDate();
    return currentDay === Number(day);
  });
  return allEvents;
};

export const formatToLocalTime = (date: string) => {
  const isoDate = parseISO(date);
  return format(isoDate, "p");
};

export const convertLocalDateToUTC = (htmlDate = "", htmlTime = "") => {
  const [year, month, day] = htmlDate.split("-");
  const [hour, minute] = htmlTime.split(":");

  // Create local time with form input
  const localDate = new Date(Number(year), Number(month) - 1, Number(day), Number(hour), Number(minute));

  // Convert local date to UTC date format
  return Date.UTC(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate(), localDate.getUTCHours(), localDate.getUTCMinutes(), localDate.getUTCSeconds());
};

//happens on event submit
export const generateRecurringDatesArray = ({ initialDate, startTime, finalDate, endTime, title, description, location, recurring }: any) => {
  // If event is not recurring, generate just one event for dates array and return.
  if (recurring.rate === "noRecurr") {
    return [
      {
        groupId: null,
        startAt: convertLocalDateToUTC(initialDate, startTime),
        endAt: convertLocalDateToUTC(finalDate, endTime),
      },
    ];
  }

  // Generate UTC time from HTML input (date and time).
  const startUTC = convertLocalDateToUTC(initialDate, startTime);
  const endUTC = convertLocalDateToUTC(finalDate, endTime);

  // Generate a range of dates in between initialDate & endDate (date-fns does not generate the time sadly)
  const initialDatesToFinalDates = eachDayOfInterval({
    start: startUTC,
    end: endUTC,
  });

  // Filter out dates that are not recurring
  const result = initialDatesToFinalDates.filter((date: any) => {
    return recurring.days.some((day: string) => day === format(date, "cccc"));
  });

  // Recurring events have the same group id. This allows deleting them all at once by this id.
  const groupId = nanoid();

  // Create recurring dates array with events information
  const dates = result.map((date: any) => {
    const [month, day, year] = format(date, "P").split("/");
    const htmlDateFormat = `${year}-${month}-${day}`;

    // Recreate date with time added
    const newStartDate = convertLocalDateToUTC(htmlDateFormat, startTime);
    const newEndDate = convertLocalDateToUTC(htmlDateFormat, endTime);

    return {
      groupId,
      startAt: newStartDate,
      endAt: newEndDate,
    };
  });
  return dates;
};
