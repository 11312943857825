import * as React from "react";
import { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

interface DatePickerProps {
  label: string;
  name: string;
  value: Dayjs | null;
  setValue: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  validate?: { error: boolean; message: string };
  required?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}
export const VADatePicker: React.FC<DatePickerProps> = ({ label, name, value, setValue, validate, required, onChange, onBlur }) => {
  const handleChange = (newValue: Dayjs | null) => {
    setValue(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        label={label}
        inputFormat="DD/MM/YYYY"
        value={value}
        onChange={handleChange}
        renderInput={(params) => <TextField name={name} required={required} error={validate?.error} helperText={validate?.message} onChange={onChange} onBlur={onBlur} {...params} />}
      />
    </LocalizationProvider>
  );
};
