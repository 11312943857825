import { Box, Modal } from "@mui/material";
import { ReactElement } from "react";
import { VAButton } from "../VAButton/VAButton.component";

interface ModalProps {
  open: boolean;
  setOpen: any;
  cancelClick: any;
  data: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderStyle: "none",
  borderRadius: "43px",
  boxShadow: 24,
};

export const ConfirmationModal = ({ open, setOpen, cancelClick, data }: ModalProps): ReactElement => {
  return (
    <div>
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div className="flex flex-col justify-center items-center w-[350px] px-[20px] md:w-[500px] md:px-[60px] py-[20px] ">
            <div className="max-w-[360px] flex flex-col gap-[22px] mt-[17px]">
              <h2 className="text-[20px] font-[600] text-[#000000]">Are you sure you want to cancel this appointment?</h2>
            </div>
            <div className="flex flex-col gap-[20px] mt-[28px] w-full">
              <VAButton
                text="Yes"
                dark
                onClick={() => {
                  cancelClick(data);
                  setOpen(false);
                }}
              />
              <VAButton text="No" onClick={() => setOpen(false)} outlined />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
