import { ReactElement } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";

interface FormUploadFileProps {
  name: string;
  label: string;
  value: string | File | null;
  validate?: { error: boolean; message: string };
  required?: boolean;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}
const CssTextField = styled(TextField)({
  "&": {
    width: "100%",
    borderRadius: "50px",
  },
  "& label.Mui-focused": {
    color: "#888A8C",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#CDCFD2",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",

    "& fieldset": {
      borderColor: "#CDCFD2",
    },
    "&:hover fieldset": {
      borderColor: "#CDCFD2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#CDCFD2",
    },
  },
});

export const FormUploadFile = ({ name, label, value, validate, required, onChange, onBlur }: FormUploadFileProps): ReactElement => {
  // const [fileName, setFileName] = useState('');

  // const onChange = (e: any) => {
  //   console.log(e);
  //   setFileName(e?.target?.files?.[0]?.name);
  // };
  return (
    <div>
      <CssTextField
        name={name}
        label={label}
        value={value}
        error={validate?.error}
        helperText={validate?.message}
        required={required}
        onBlur={onBlur}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <IconButton color="default" aria-label="upload picture" component="label">
              <input hidden name={name} type="file" onChange={onChange} />
              <FolderOutlinedIcon color="inherit" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
};
