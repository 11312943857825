import Common from "./Common";
import SignUp from "./SignUp";
import Login from "./Login";
import ScheduleVaccine from "./ScheduleVaccine";

const reducers = {
  common: Common,
  signup: SignUp,
  ScheduleVaccine: ScheduleVaccine,
  Login: Login,
};

export default reducers;
