import { useState } from "react";
import { Box, Tab, Tabs, styled } from "@mui/material";
import TestRecordAccordian from "./Accordian";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface StyledTabProps {
  label: string;
}
const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  // fontSize: theme.typography.pxToRem(15),
  // marginRight: theme.spacing(1),
  // color: 'rgba(255, 255, 255, 0.7)',
  // '&.Mui-selected': {
  //   color: '#fff',
  // },
  // '&.Mui-focusVisible': {
  //   backgroundColor: 'rgba(100, 95, 228, 0.32)',
  // },
}));
interface TestRecordTabsProps {
  value: number;
  handleChange: any;
  shareTestRecordsVerified: any;
  shareTestRecordsUnverified: any;
}
export default function TestRecordTabs({ value, handleChange, shareTestRecordsVerified, shareTestRecordsUnverified }: TestRecordTabsProps) {
  const [tabs] = useState(["Verified", "Unverified"]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {tabs.map((tab: string, idx: number) => {
            return <StyledTab label={tab} {...a11yProps(idx)} />;
          })}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <TestRecordAccordian data={shareTestRecordsVerified} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <TestRecordAccordian data={shareTestRecordsUnverified} />
      </CustomTabPanel>
    </Box>
  );
}
