import { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { VAButton, DashboardLayout } from "../../components";
import { getScheduledVaccineData } from "../../redux/actionCreators";
import { AppState } from "../../redux/store";
import { VaccineCards } from "../../sections/Dashboard";
import { Back } from "../../icons";

export const ScheduleTest = (): ReactElement => {
  const navigate = useNavigate();
  //user email
  const userData = useSelector<AppState, AppState["Login"]>(({ Login }) => Login);
  const [scheduledVaccineData, setScheduledVaccineData] = useState([]);

  const getScheduledData = async (date: any) => {
    let data = {
      date: date,
      email: userData.userData.email,
      type: "TEST",
    };
    const res = await getScheduledVaccineData(data);

    setScheduledVaccineData(res.data);
  };

  useEffect(() => {
    let date = new Date();
    getScheduledData(date);
  }, []);

  return (
    <DashboardLayout>
      <div>
        <div className="flex items-center gap-[5px] cursor-pointer mt-[13px]">
          <Back />
          <Link to="/home" className="text-[16px] font-[600] text-[#888A8C]">
            Back
          </Link>
        </div>
        <div className="flex items-center justify-between">
          <h2 className="text-[24px] text-primary-main font-[600]">{scheduledVaccineData.length > 0 ? "Schedule Diagnostic Test" : "You have no appointments scheduled"}</h2>
          <VAButton dark text={scheduledVaccineData.length > 0 ? "Book appointment" : "Click here to book appointment"} onClick={() => navigate("/scheduletestappointment")} />
        </div>
        {/* //Calendar View */}
        {scheduledVaccineData.length > 0 ? <VaccineCards setScheduledVaccineData={setScheduledVaccineData} scheduledVaccineData={scheduledVaccineData} /> : null}
        <div className="w-[full] flex gap-[12px] justify-between items-center px-[20px] py-[12px] rounded-t-[12px] gap-[20px]">
          <span className="border-t-[1px] border-primary-main"></span>
        </div>
      </div>
    </DashboardLayout>
  );
};
