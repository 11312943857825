// Create Profile Action Creator
import jwtAxios from "../../services/auth/jwt-auth";

export const onGetHealthConditions = async () => {
  try {
    const res = await jwtAxios.post("/profile/getHealthConditions", {});
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const onCreateProfile = async (createProfiledata: any) => {
  try {
    const res = await jwtAxios.post("/profile/createprofile", createProfiledata);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
