import { Dispatch, ReactElement, SetStateAction } from "react";
import { Divider } from "@mui/material";
import { Dayjs } from "dayjs";
import { AutoComplete, FormInput, FormUploadFile, FromSelect, NumberFormatField, VADatePicker } from "../../components";
import { CreateProfileModel, ErrorsCreateProfileModel } from "../../types/models/CreateProfile";

interface CreateProfileProps {
  inputs: CreateProfileModel;
  states: any;
  cities: any;
  dateValue: Dayjs | null;
  setDateValue: Dispatch<SetStateAction<Dayjs | null>>;
  errorsCreateProfile: ErrorsCreateProfileModel;
  validateErrorCreateProfile: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleAutoComplete?: (event: React.SyntheticEvent, value: any | [], name: any) => void;
}

const genderTypes = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];

export const CreateProfileSection = ({
  inputs,
  states,
  handleAutoComplete,
  cities,
  dateValue,
  setDateValue,
  errorsCreateProfile: errors,
  validateErrorCreateProfile: validateError,
  onChange,
}: CreateProfileProps): ReactElement => {
  return (
    <>
      <div>
        <div className="mt-[22px]">
          {/* <div>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <Paper sx={{ borderRadius: "50%" }}>
                  <Edit sx={{ fontSize: 15 }} className="text-[#0f5175]" />
                </Paper>
              }
            >
              <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" sx={{ width: 80, height: 80 }} />
            </Badge>
          </div> */}
          <div className="mt-[20px]">
            <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px]">
              <FormInput
                name="first_name"
                label="Legal First Name"
                value={inputs.first_name}
                validate={{ error: errors.first_name.error, message: errors.first_name.message }}
                required
                onChange={onChange}
                onBlur={validateError}
              />
              <FormInput
                name="last_name"
                label="Legal Last Name"
                value={inputs.last_name}
                validate={{ error: errors.last_name.error, message: errors.last_name.message }}
                required
                onChange={onChange}
                onBlur={validateError}
              />
            </div>
            <div className="mt-[30px]">
              <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px]">
                <FormInput
                  name="email"
                  label="Email"
                  value={inputs.email}
                  validate={{ error: errors.email.error, message: errors.email.message }}
                  required
                  onChange={onChange}
                  onBlur={validateError}
                />
                <FromSelect
                  name="gender"
                  label="Gender"
                  value={inputs.gender}
                  validate={{ error: errors.gender.error, message: errors.gender.message }}
                  required
                  onChange={onChange}
                  options={genderTypes}
                  onBlur={validateError}
                />
              </div>
            </div>
            <div className="mt-[30px]">
              <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px]">
                <NumberFormatField
                  name="ph_no"
                  label="Phone number"
                  value={inputs.ph_no}
                  validate={{ error: errors.ph_no.error, message: errors.ph_no.message }}
                  required
                  onChange={onChange}
                  onBlur={validateError}
                />
                <VADatePicker
                  name="dob"
                  label="Date of Birth"
                  value={dateValue}
                  setValue={setDateValue}
                  validate={{ error: errors.dob.error, message: errors.dob.message }}
                  required
                  onChange={onChange}
                  onBlur={validateError}
                />
              </div>
            </div>
            <div className="mt-[30px]">
              <Divider />
            </div>
            <div className="mt-[30px]">
              <div className="grid grid-cols-2 items-center gap-[50px]">
                <div className="grid grid-cols-1 items-center gap-[25px]">
                  <FormInput
                    name="address"
                    label="Address"
                    value={inputs.address}
                    validate={{ error: errors.address.error, message: errors.address.message }}
                    required
                    onChange={onChange}
                    onBlur={validateError}
                  />
                  <AutoComplete
                    name="state"
                    label="State"
                    value={inputs.state}
                    options={states}
                    validate={{ error: errors.state.error, message: errors.state.message }}
                    required
                    onChange={handleAutoComplete}
                    onBlur={validateError}
                  />
                  {inputs.state !== null && Object.keys(inputs.state).length > 0 && cities.length === 0 ? (
                    <FormInput name="city" label="City" value={inputs.city} validate={{ error: errors.city.error, message: errors.city.message }} required onChange={onChange} onBlur={validateError} />
                  ) : (
                    <AutoComplete
                      name="city"
                      label="City"
                      value={inputs.city}
                      validate={{ error: errors.city.error, message: errors.city.message }}
                      required
                      onBlur={validateError}
                      options={cities}
                      onChange={handleAutoComplete}
                    />
                  )}
                  {/* <FormInput name="city" label="City" value={inputs.city} validate={{ error: errors.city.error, message: errors.city.message }} required onChange={onChange} onBlur={validateError} /> */}
                </div>
                <div className="mt-[30px]">
                  <div className="grid grid-cols-1 items-center gap-[25px]">
                    <FormUploadFile name="hic" label="Upload Health Insurance Card" value={inputs.hic} onChange={onChange} />
                    <FormUploadFile name="phar_bc" label="Upload pharmacy benefit Card" value={inputs.phar_bc} onChange={onChange} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
