import { ReactElement } from "react";
import "./LoginForm.styles.scss";
import { VAButton, FormInput } from "../../components";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { ErrorsLoginModel, LoginModel } from "../../types/models/Login";
interface LoginFormProps {
  inputs: LoginModel;
  errorsLoginInputs: ErrorsLoginModel;
  validateErrorLoginInfo: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleSubmit: any;
}
export const LoginForm = ({ inputs, errorsLoginInputs: errors, validateErrorLoginInfo: validateError, onChange, handleSubmit }: LoginFormProps): ReactElement => {
  return (
    <>
      <div className="flex flex-col gap-[30px]">
        <FormInput
          name="email"
          label="Email"
          type="email"
          value={inputs.email}
          validate={{ error: errors.email.error, message: errors.email.message }}
          required
          onChange={onChange}
          onBlur={validateError}
        />
        <FormInput
          name="password"
          label="Password"
          type="password"
          value={inputs.password}
          validate={{ error: errors.password.error, message: errors.password.message }}
          required
          onChange={onChange}
          onBlur={validateError}
        />
        <div className="forget_password_div">
          <Link to="/forgot-password" className="text-[16px] text-[#000000] text-align-[end] font-[400px]">
            Forget password?
          </Link>
        </div>
        <div className="flex gap-[12px]">
          <Box>
            <Checkbox color="success" />
            <span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] pt-[20px] mt[revert]">Remember me</span>
          </Box>
        </div>
        <div>
          <VAButton text="Login" dark onClick={handleSubmit} />
        </div>
      </div>
    </>
  );
};
