import { AppActions } from "../../types";
import { SIGN_UP } from "../../types/actions/Signup.actions";
import { PatientInfoModel } from "../../types/models/Patient";

const initialState: {
  signupData: PatientInfoModel;
} = {
  signupData: {
    email: "",
    password: "",
    confirm_password: "",
    ConditionCheck: false,
    verificationCode: 0,
  },
};
const SignUp = (state = initialState, action: AppActions) => {
  switch (action.type) {
    case SIGN_UP:
      return {
        ...state,
        signupData: action.payload,
      };
    default:
      return state;
  }
};
export default SignUp;
