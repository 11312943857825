import { ReactElement } from "react";

export const Notification = (): ReactElement => {
  return (
    <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.961 41.4122C26.961 38.3768 24.4917 35.9162 21.4453 35.9162C18.399 35.9162 15.9296 38.3768 15.9296 41.4122C15.9296 44.4476 18.399 46.9081 21.4453 46.9081C24.4917 46.9081 26.961 44.4476 26.961 41.4122V41.4122Z"
        fill="#F7C92B"
      />
      <path
        d="M21.4453 35.9162C20.8877 35.9162 20.3495 35.9994 19.8422 36.1529C22.1064 36.837 23.7548 38.9322 23.7548 41.4122C23.7548 43.8918 22.1064 45.9873 19.8422 46.6715C20.3495 46.825 20.8877 46.9081 21.4453 46.9081C24.4917 46.9081 26.9611 44.4476 26.9611 41.4122C26.9611 38.3768 24.4917 35.9162 21.4453 35.9162V35.9162Z"
        fill="#F2B51D"
      />
      <path
        d="M22.0898 9.33565C20.3182 9.69029 18.594 8.54713 18.2379 6.78183C17.8819 5.01689 19.0294 3.29857 20.8007 2.94394C22.5719 2.5893 24.2965 3.73246 24.6526 5.49775C25.0082 7.26269 23.8611 8.98101 22.0898 9.33565Z"
        fill="#F7C92B"
      />
      <path
        d="M24.6526 5.49775C24.2965 3.73246 22.572 2.5893 20.8007 2.94394C20.6358 2.97692 20.4766 3.02211 20.3232 3.07733C21.3572 3.45456 22.176 4.34312 22.4089 5.49775C22.7318 7.09846 21.8178 8.6608 20.3236 9.20226C20.8704 9.40163 21.4772 9.45829 22.0898 9.33565C23.8611 8.98102 25.0083 7.26269 24.6526 5.49775Z"
        fill="#F2B51D"
      />
      <path
        d="M35.1065 40.4925H7.78375C5.33883 40.4925 3.35703 38.5178 3.35703 36.0819C3.35703 34.7333 3.9696 33.4571 5.02302 32.6105L5.90075 31.9048C7.93106 30.2733 9.11167 27.8137 9.11167 25.2147V20.5794C9.11167 13.7401 14.6755 8.19611 21.5393 8.19611C28.4027 8.19611 33.9669 13.7401 33.9669 20.5794V25.3004C33.9669 27.8503 35.1033 30.269 37.0689 31.9019L37.9237 32.6119C38.9437 33.4593 39.5333 34.7143 39.5333 36.0375V36.0819C39.5333 38.5178 37.5515 40.4925 35.1065 40.4925Z"
        fill="#FFB547"
      />
      <path
        d="M37.9238 32.6119L37.0691 31.9019C35.1035 30.2686 33.9671 27.8503 33.9671 25.3001V20.579C33.9671 13.7401 28.4028 8.19574 21.5394 8.19574C20.3233 8.19574 19.1484 8.37073 18.0379 8.69524C23.1965 10.2016 26.9639 14.9518 26.9639 20.579V25.3001C26.9639 27.8503 28.1003 30.2686 30.066 31.9019L30.9211 32.6119C31.9407 33.4592 32.5306 34.7143 32.5306 36.0374V36.0819C32.5306 38.5178 30.5488 40.4925 28.1039 40.4925H35.1071C37.5516 40.4925 39.5334 38.5178 39.5334 36.0819V36.0374C39.5334 34.7143 38.9438 33.4592 37.9238 32.6119V32.6119Z"
        fill="#C77700"
      />
    </svg>
  );
};
