import React, { ReactElement, useEffect, useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { Link } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import { AppBar, Card, Checkbox, Dialog, IconButton, Stack, Toolbar, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { VAButton, CustomStepper, DashboardCard, DashboardLayout, FormInput, PrePaperWorkModal, VADatePicker } from "../../components";
import { Back } from "../../icons";
import { useLocation } from "react-router-dom";
import { getVaccineScreeningData, saveScreeningQuizAnswers } from "../../redux/actionCreators";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/store";
import { AlertType } from "../../shared/constants/AppConst";
import VAAlert from "../../components/Alert/Alert";
import PDFViewer from "./PDFViewer";
import PDFIcon from "../../icons/PDFIcon.icon";

export const FillDetails = (): ReactElement => {
  const [activeStep, setActiveStep] = useState(0);
  const [steps] = useState(["Screening Questions", "Consent Form", "VIS Information"]);
  const location = useLocation();
  const [selectedVacScrData, setSelectedVacScrData] = useState<any>([]);
  const [selectedVacData, setSelectedVacData] = useState<any>([]);
  const [ddOpen, setddOpen] = useState<boolean>(false);
  const [prev, setPrev] = useState<boolean>(false);
  const [selectedDropdown, setSelectedDropdown] = useState<string | undefined>(undefined);
  const [checkedConsentTerms, setCheckedConsentTerms] = useState<boolean | undefined>(false);
  const [visChecked, setVisChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [schedule_appointment_id, setScheduleAppointmentId] = useState<string | null>();
  const [signature, setSignature] = useState("");
  const [dateOfSignature, setDateOfSignature] = useState<Dayjs | null>(dayjs());
  const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);
  const [visModalOpen, setVISModalOpen] = useState(false);
  const [visPath, setVISPath] = useState("");
  const [visTitle, setVISTitle] = useState("");
  const [visFileName, setVISFileName] = useState("");
  //user email
  const { userData } = useSelector<AppState, AppState["Login"]>(({ Login }) => Login);

  const onChange = (e: any) => {
    if (e.target.name === "signature") setSignature(e.target.value);
    if (e.target.name === "dateOfSignature") setDateOfSignature(e.target.value);
  };

  const handleStepOne = async () => {
    let allChecked = true;
    for (let i = 0; i < selectedVacScrData.length; i++) {
      let array = selectedVacScrData[i].screening_questions;
      for (let j = 0; j < array.length; j++) {
        let optionsArray = array[j].options;
        if (optionsArray[0].isChecked === false && optionsArray[1].isChecked === false && optionsArray[2].isChecked === false) {
          allChecked = false;
          break;
        }
      }
    }
    if (allChecked === true) {
      setActiveStep(1);
    } else {
      setAlertMessage({ type: "error", description: "Please answer all the questions", open: true });
    }
  };

  const handleStepTwo = async () => {
    let proceed = true;
    if (dateOfSignature === null || checkedConsentTerms === false || signature === "") {
      proceed = false;
    }

    if (proceed === true) {
      setActiveStep(2);
    } else {
      setAlertMessage({ type: "error", description: "Please fill all fields and accept the consent", open: true });
    }
  };

  const handleFinalStep = async () => {
    let proceed2 = true;
    if (visChecked === false) {
      proceed2 = false;
    }

    if (proceed2 === true) {
      const searchParams = new URLSearchParams(location.search);
      let data = {
        patient_email: userData.email,
        schedule_appointment_id: schedule_appointment_id,
        biz_branch_id: searchParams.get("biz_branch_id"),
        screening_data: selectedVacScrData,
        signature,
        date_of_signature: dateOfSignature?.toDate().toUTCString(),
        type: "VACCINE",
      };
      let res = await saveScreeningQuizAnswers(data);
      setAlertMessage({ type: "success", description: res.message, open: true });
      setOpen(true);
    } else {
      setAlertMessage({ type: "error", description: "Please accept the VIS terms and conditions", open: true });
    }
  };

  const handleChangeVis = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVisChecked(!visChecked);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedConsentTerms(!checkedConsentTerms);
  };

  const onCheckBoxClick = (element: any, data: any, option: any) => {
    option.isChecked = !option.isChecked;
    data.options.filter((x: any) => x.options !== option.options).forEach((y: any) => (y.isChecked = false));
    setSelectedVacScrData((x: any) => x.map((i: any) => (i.screening_id === element.screening_id ? element : i)));
  };

  const openDD = (name: string) => {
    setddOpen(!ddOpen);
    setSelectedDropdown(name);
  };

  const [slot, setSlot] = useState<any>("");
  const [date, setDate] = useState<any>("");
  const [loc, setLoc] = useState<any>("");

  const getData = async () => {
    const searchParams = new URLSearchParams(location.search);
    let schedule_appointment_id = searchParams.get("sa");
    let slot = searchParams.get("t");
    let date = searchParams.get("date");
    let loc = searchParams.get("loc");
    setScheduleAppointmentId(schedule_appointment_id);
    setSlot(slot);
    setDate(date);
    setLoc(loc);

    let data = {
      vaccine_id: searchParams.get("v"),
      biz_branch_id: searchParams.get("biz_branch_id"),
    };
    const res = await getVaccineScreeningData(data);
    setSelectedVacScrData(res.data.screeningQuestions);
    setSelectedVacData(res.data);
    console.log(res.data);
  };
  const viewVISClick = async (data: any) => {
    try {
      setVISModalOpen(true);
      setVISPath(data.vis_path);
      setVISTitle(data.vis_title);
      setVISFileName(data.vis_file_name);
    } catch (err) {
      console.log(err);
    }
  };
  const ViewVISDialog = () => {
    return (
      <Dialog fullScreen open={visModalOpen} onClose={() => setVISModalOpen(false)}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => setVISModalOpen(false)} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {visTitle}
            </Typography>
          </Toolbar>
        </AppBar>

        <PDFViewer filePath={visPath + "/" + visFileName} />
      </Dialog>
    );
  };
  useEffect(() => {
    getData();
    setActiveStep(0);
  }, [prev]);

  return (
    <React.Fragment>
      <DashboardLayout>
        <ViewVISDialog />
        <div>
          <div className="flex items-center gap-[5px] cursor-pointer mt-[13px] mb-[8px]">
            <Back />
            {activeStep === 0 ? (
              <Link to="/home" className="text-[16px] font-[600] text-[#888A8C]">
                Back
              </Link>
            ) : activeStep === 1 ? (
              <Link to="#" className="text-[16px] font-[600] text-[#888A8C]" onClick={() => setActiveStep(0)}>
                Back
              </Link>
            ) : (
              <Link to="#" className="text-[16px] font-[600] text-[#888A8C]" onClick={() => setActiveStep(1)}>
                Back
              </Link>
            )}
          </div>
          <div className="flex items-center justify-between py-[20px]">
            <h2 className="text-[24px] text-primary-main font-[600]">Pre-appointment Paperwork</h2>
          </div>
          <Card style={{ padding: "20px" }}>
            <CustomStepper activeStep={activeStep} steps={steps} />
          </Card>
          <br />

          {activeStep === 0 ? (
            <>
              <div className="mt-[24px]" style={{ marginTop: "20px" }}>
                {selectedVacScrData?.map((element: any, index: number) => (
                  <div className="items-center justify-between pb-[9px]" key={element.screening_id}>
                    <div onClick={() => openDD(element.screening_name)} className="flex items-center justify-between cursor-pointer pb-[9px] border-b-[1px] border-[#878a8c] py-[8px]">
                      <h2 className="text-[18px] font-[600] text-primary-main">{element.screening_name}</h2>
                      <div className="flex gap-[10px] items-center cursor-pointer">
                        <span>
                          <AiFillCaretDown />
                        </span>
                      </div>
                    </div>
                    {ddOpen && selectedDropdown === element.screening_name ? (
                      <div className={`transition-all`}>
                        {element.screening_questions?.map((data: any) => (
                          <div className="border-b-[1px] border-[#9a9ea1] py-[8px]">
                            <p className="text-[14px] font-[400] text-primary-main">{data.screening_Questions_List.questions}</p>
                            <div className="flex flex-col gap-[8px] md:gap-[80px] md:flex-row">
                              {data.options?.map((option: any) => (
                                <>
                                  <div key={option} className="flex items-center ">
                                    <span key={option} className="text-[#1F2124] text-[14px] font-[400]">
                                      <ol>
                                        <li>
                                          <Checkbox checked={option.isChecked} onChange={() => onCheckBoxClick(element, data, option)} color="success" />
                                          {option.options}
                                        </li>
                                      </ol>
                                    </span>
                                  </div>
                                </>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
              <div className="mt-[40px] flex grid grid-cols-2 mb-[20px]">
                <VAButton text="Save and Continue" dark onClick={handleStepOne} />
              </div>
            </>
          ) : activeStep === 1 ? (
            <React.Fragment>
              <div className="flex flex-col gap-[15px]">
                <FormInput name="firstName" label="Legal First Name" type="text" value={userData.first_name} readOnly />

                <FormInput name="lastName" label="Legal Last Name" type="text" value={userData.last_name} readOnly />
                <VADatePicker label="Date of Signature" name="dateOfSignature" value={dateOfSignature} setValue={setDateOfSignature} required onChange={onChange} />
                <p style={{ textAlign: "justify", fontSize: "0.8rem" }}>{selectedVacData !== undefined ? selectedVacData.vaccine_consent_text : null}</p>

                <p>
                  <Checkbox checked={checkedConsentTerms} onChange={handleChange} inputProps={{ "aria-label": "controlled" }} color="success" />I agree to the terms and conditions.
                </p>

                <FormInput name="signature" label="Patient Signature" type="text" variant="standard" cursive value={signature} required onChange={onChange} />
              </div>
              <div className="mt-[40px] flex grid grid-cols-2 mb-[20px]">
                <VAButton text="Save and Continue" dark onClick={handleStepTwo} />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {selectedVacData?.vis?.map((item: any) => (
                <DashboardCard>
                  <Stack spacing={{ xs: 1, sm: 2 }} direction="row">
                    <div className="mt-[18px] ml-[18px]">
                      <PDFIcon />
                    </div>
                    <button className="text-[#114059] text-[18px] font-[600] mt-[18px] pt-[20px] pb-[20px] pr-[20px] cursor-pointer" onClick={() => viewVISClick(item)}>
                      {item.vis_title}
                    </button>
                  </Stack>
                </DashboardCard>
              ))}
              <div className="flex flex-col gap-[15px]">
                <p>
                  <Checkbox checked={visChecked} onChange={handleChangeVis} inputProps={{ "aria-label": "controlled" }} color="success" />
                  By checking this box, I verify that the VIS form has been received and reviewed.
                </p>

                <FormInput name="firstName" label="Legal First Name" type="text" value={userData.first_name} readOnly />

                <FormInput name="lastName" label="Legal Last Name" type="text" value={userData.last_name} readOnly />
              </div>
              <div className="mt-[40px] flex grid grid-cols-2 mb-[20px]">
                <VAButton text="Continue and Submit" dark onClick={handleFinalStep} />
              </div>
            </React.Fragment>
          )}
        </div>
      </DashboardLayout>
      <PrePaperWorkModal open={open} setOpen={setOpen} slot={slot} date={date} loc={loc} />
      {alertMessage !== null ? <VAAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} /> : null}
    </React.Fragment>
  );
};
