import { ReactElement } from "react";
import { DashboardLayout } from "../../components";

export const ChangePassword = (): ReactElement => {
  return (
    <div>
      <DashboardLayout>
        <h1>Under Development</h1>
      </DashboardLayout>
    </div>
  );
};
