import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { VAButton, VerticalStepper } from "../../components";
import { AppState } from "../../redux/store";
export const FinalWelcome = (): ReactElement => {
  const navigate = useNavigate();
  const { userData } = useSelector<AppState, AppState["Login"]>(({ Login }) => Login);

  return (
    <div className="outer">
      <div className="outer__left">
        <div className="outer__left-main-img">
          <img src={`${process.env.PUBLIC_URL}/imgs/sider-image.png`} alt="Vaccifi" className="object-cover fixed w-[55%] h-full" />
        </div>
        <div className="outer__left-secondary-img">
          <img src={`${process.env.PUBLIC_URL}/imgs/sider-text-image.svg`} alt="vaccine text" />
        </div>
      </div>
      <div className="w-full p-[20px]  flex justify-center items-center md:w-[45%]">
        <div className="w-[380px]">
          <div>
            <img src={`${process.env.PUBLIC_URL}/imgs/final-welcome.png`} alt="vaccine text" className="mx-auto w-[200px] h-[200px]" />
            <h2 className="text-[34px] font-[600] text-[#0F5175] mt-[12px]">Welcome {userData.name} !</h2>
            <p className="text-[14px] font-[400] text-[rgba(50,53,57,0.4)]">Get your vaccination record in 3 easy steps.</p>
          </div>
          <div className="mt-[30px]">
            <VerticalStepper />
          </div>
          <div>
            <VAButton
              text="Get Started"
              dark
              onClick={() => {
                navigate("/home");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
