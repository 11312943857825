import jwtAxios from "../../services/auth/jwt-auth";
import { ResetPassModel } from "../../types/models/ResetPass";

export const onSubmitResetPass = async (resetPassData: ResetPassModel) => {
  try {
    const res = await jwtAxios.post("/user/resetPass", { resetPassData });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
