import { ReactElement, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Box, Popover, Grid, Button } from "@mui/material";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import { DashboardCard, VAButton, ViewPaperworkModal, ConfirmationModal } from "../../components";
import { SelectDateAndTime } from "../../pages/selectDateAndTime";
import { getScheduledData, rescheduleAppointment, getScheduledVaccineData, deleteSelectedRecord, viewPaperwork } from "../../redux/actionCreators";
import { AppState } from "../../redux/store";
import { createTimeSlots, getFormattedDate } from "../../shared/Util";

import { AlertType } from "../../shared/constants/AppConst";
import VAAlert from "../../components/Alert/Alert";

interface vaccineCardsProps {
  setScheduledVaccineData: any;
  scheduledVaccineData: any;
}

export const VaccineCards = ({ setScheduledVaccineData, scheduledVaccineData }: vaccineCardsProps): ReactElement => {
  const location = useLocation();

  const vaccineType = location.pathname === "/schedule-vaccines" ? "VACCINE" : "TEST";
  const [open, setOpen] = useState(false);
  const [calendarPop, setCalendarPop] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [scheduleData, setScheduleData] = useState<any>([]);
  const [openPopover, setOpenPopover] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [selectedVaccineScheduleId, setSelectedVaccineScheduleId] = useState(0);
  const [openPaperworkModal, setopenPaperworkModal] = useState(false);
  const [paperWorkModalData, setPaperWorkModalData] = useState({});
  const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);
  const filteredData = selectedDate ? scheduleData.filter((data: any) => data.date === selectedDate) : scheduleData;

  let availableTimeSlots: any = [];
  if (filteredData.length > 0) availableTimeSlots = createTimeSlots(filteredData[0].scheduledVaccines);

  const userData = useSelector<AppState, AppState["Login"]>(({ Login }) => Login);

  const handlesSelectedTimeSlot = async (slot: string, vs_id: any) => {
    setSelectedTimeSlot(slot);
  };

  const rescheduleClick = async (item: any) => {
    let data = {
      biz_branch_id: item.biz_branch_id,
      // vaccine_id: scheduledVaccineData[index].Vaccine_id,
    };
    let scheduleData = await getScheduledData(data);
    setScheduleData(scheduleData.data);

    //set already selected date and slot
    let date = item.date;
    let slot = item.slot;

    setSelectedDate(date);
    setSelectedTimeSlot(slot);
    setSelectedVaccineScheduleId(item.schedule_appointment_id);
    setCalendarPop(true);
  };
  const viewPaperWorkClick = async (item: any) => {
    try {
      let res = await viewPaperwork({ schedule_appointment_id: item.schedule_appointment_id });
      if (res?.screeningData.length > 0) {
        setPaperWorkModalData(res);
        setopenPaperworkModal(true);
      } else {
        if (vaccineType === "VACCINE") {
          setAlertMessage({ type: "info", description: "Please complete the paperwork first", open: true });
        } else {
          setPaperWorkModalData(res);
          setopenPaperworkModal(true);
        }
      }
    } catch (err) {
      console.log("getScreeningData Error: ", err);
    }
  };

  const reschedule = async () => {
    let data = {
      schedule_appointment_id: selectedVaccineScheduleId,
      date: selectedDate,
      slot: selectedTimeSlot,
    };
    let res = await rescheduleAppointment(data);
    setAlertMessage({ type: "success", description: res.message, open: true });

    let data2 = {
      // date: selected_date,
      email: userData.userData.email,
      type: vaccineType,
    };
    const res2 = await getScheduledVaccineData(data2);
    setScheduledVaccineData(res2.data);
    setOpenPopover(false);
    setCalendarPop(false);
  };

  const cancelClick = async (dataObj: any) => {
    let res = await deleteSelectedRecord({ schedule_appointment_id: dataObj.schedule_appointment_id, vaccine_type: dataObj.vaccine_type });

    let data = {
      email: userData.userData.email,
      type: vaccineType,
    };

    const res2 = await getScheduledVaccineData(data);
    setScheduledVaccineData(res2.data);
    setAlertMessage({ type: "success", description: res.message, open: true });
  };
  return (
    <Fragment>
      {scheduledVaccineData.map((item: any, index: any) => {
        const bgColor = item.paperwork_status.includes("done") ? "bg-[#DCFFDD]" : "bg-[#FDCED1]";
        return (
          <div className="flex flex-col gap-[20px] m-[20px]">
            <DashboardCard>
              <div className={`flex items-center justify-between ${bgColor} py-[20px] px-[20px] py-[12px] rounded-t-[12px]`}>
                <h2 className="text-[16px] text-primary-main font-[400] text-[#1F2124]">
                  <VaccinesIcon style={{ color: "#45818e" }} />
                  {item.vaccine}
                </h2>
                <span className="text-[16px] font-[400] text-primary-main text-[#1F2124]">{getFormattedDate(item.date) + " " + item.slot}</span>
              </div>
              <div className="bg-[#FFFFFF] p-[20px] rounded-b-[12px]">
                <div className="flex items-center justify-between border-b-[1px] border-[ #0F5175] pb-[9px]">
                  <h2 className=" font-[500] text-primary-main">Status: {item.paperwork_status}</h2>
                  <h2 className="text-[16px] font-[500] text-primary-main">{item.prov_location}</h2>
                </div>
                <div className="flex justify-between items-center border-b-[1px] border-[ #0F5175] ">
                  <Box display="flex" style={{ display: "flex", flexWrap: "nowrap", width: "100%" }}>
                    <ArticleOutlinedIcon style={{ color: "#45818e" }} />
                    <Link
                      to={`${vaccineType === "VACCINE" ? "/fill-details" : "/fill-test-details"}?v=${item.Vaccine_id}&biz_branch_id=${item.biz_branch_id}&sa=${item.schedule_appointment_id}&t=${
                        item.slot
                      }&loc=${item.prov_location}&date=${getFormattedDate(item.date)}`}
                      className="text-primary-main"
                    >
                      Pre-appointment paperwork
                    </Link>
                    <Box m={1} />
                    <RestoreOutlinedIcon style={{ color: "#45818e" }} />
                    <Link to={""} className="text-primary-main" onClick={() => rescheduleClick(item)}>
                      Reschedule
                    </Link>
                    <Box m={1} />
                    <RestoreOutlinedIcon style={{ color: "#45818e" }} />
                    <Link to={""} className="text-primary-main" onClick={() => viewPaperWorkClick(item)}>
                      View Paperwork
                    </Link>
                    <Box m={1} />
                    <CancelOutlinedIcon style={{ color: "#45818e" }} />
                    <ConfirmationModal open={open} setOpen={setOpen} cancelClick={cancelClick} data={item} />
                    <Link to={""} className="text-primary-main" onClick={() => setOpen(true)}>
                      Cancel
                    </Link>
                  </Box>
                </div>
              </div>
            </DashboardCard>
            <Popover open={calendarPop} anchorOrigin={{ vertical: "center", horizontal: "center" }} PaperProps={{ style: { width: "35%" } }}>
              <div style={{ alignContent: "center", margin: "30px" }}>
                <div className="outer__right-header text-center">
                  <h1 className="outer__right-header-heading">Select the date to reschedule your appointment!</h1>
                </div>
                <br />
                <SelectDateAndTime selectedDate={selectedDate} scheduleData={scheduleData} setSelectedDate={setSelectedDate} setOpenPopover={setOpenPopover} />
                <br />
                <VAButton
                  text="Cancel"
                  light
                  onClick={() => {
                    setCalendarPop(false);
                  }}
                />
              </div>
            </Popover>
            <Popover open={openPopover} anchorOrigin={{ vertical: "center", horizontal: "center" }} PaperProps={{ style: { width: "35%" } }}>
              <div style={{ alignContent: "center", margin: "30px" }}>
                <div className="outer__right-header text-center">
                  <h1 className="outer__right-header-heading">Select the time slot of your appointment?</h1>
                </div>
                <br />
                <div className="flex flex-col md:grid md:items-end md:grid-cols-[6fr] justify-between w-full gap-[10px] mt-[10px] text-center">
                  {selectedDate === undefined || selectedDate === null ? null : (
                    <Grid container spacing={6}>
                      {availableTimeSlots.map((scheduledVaccine: any) => (
                        <Grid item key={scheduledVaccine} xs={4}>
                          <Button
                            variant={selectedTimeSlot === scheduledVaccine.slot ? "contained" : "outlined"}
                            fullWidth
                            onClick={() => {
                              handlesSelectedTimeSlot(scheduledVaccine.slot, scheduledVaccine.vaccine_schedule_id);
                            }}
                            style={{
                              backgroundColor: selectedTimeSlot === scheduledVaccine.slot ? "#45818e" : "transparent",
                              color: selectedTimeSlot === scheduledVaccine.slot ? "white" : "#45818e",
                            }}
                          >
                            {scheduledVaccine.slot}
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </div>
                <br />
                <VAButton text="Reschedule" dark onClick={reschedule} />
                <br />
                <VAButton
                  text="Cancel"
                  light
                  onClick={() => {
                    setOpenPopover(false);
                  }}
                />
              </div>
            </Popover>
          </div>
        );
      })}
      {openPaperworkModal && <ViewPaperworkModal openPaperworkModal={openPaperworkModal} setopenPaperworkModal={setopenPaperworkModal} paperWorkModalData={paperWorkModalData} />}
      {alertMessage !== null ? <VAAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} /> : null}
    </Fragment>
  );
};
