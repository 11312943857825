import { ReactElement, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { VAButton, DashboardLayout } from "../../components";
import { getScheduledVaccineData } from "../../redux/actionCreators";
import { AppState } from "../../redux/store";
import { VaccineCards } from "../../sections/Dashboard";
import { Back } from "../../icons";

export const Schedule = (): ReactElement => {
  const navigate = useNavigate();
  //user email
  const { userData } = useSelector<AppState, AppState["Login"]>(({ Login }) => Login);
  const [scheduledVaccineData, setScheduledVaccineData] = useState([]);
  const hasFetchedData = useRef(false); // Ref to track initial render
  useEffect(() => {
    const getScheduledData = async () => {
      if (!userData?.email || hasFetchedData.current) return;
      try {
        let date = new Date();
        let data = {
          date: date,
          email: userData.email,
          type: "VACCINE",
        };

        const res = await getScheduledVaccineData(data);

        setScheduledVaccineData(res.data);
        hasFetchedData.current = true; // Set the ref to true after the first fetch
      } catch (error) {
        console.error("Error in getScheduledData:", error);
      }
    };
    getScheduledData();
  }, [userData]);

  return (
    <DashboardLayout>
      <div>
        <div className="flex items-center gap-[5px] cursor-pointer mt-[13px]">
          <Back />
          <Link to="/home" className="text-[16px] font-[600] text-[#888A8C]">
            Back
          </Link>
        </div>
        <div className="flex items-center justify-between">
          <h2 className="text-[24px] text-primary-main font-[600]">{scheduledVaccineData.length > 0 ? "Schedule Vaccines" : "You have no appointments scheduled"}</h2>
          <VAButton dark text={scheduledVaccineData.length > 0 ? "Book appointment" : "Click here to book appointment"} onClick={() => navigate("/ScheduleAppointment")} />
        </div>
        {/* //Calendar View */}
        {scheduledVaccineData.length > 0 ? <VaccineCards setScheduledVaccineData={setScheduledVaccineData} scheduledVaccineData={scheduledVaccineData} /> : null}
        <div className="w-[full] flex gap-[12px] justify-between items-center px-[20px] py-[12px] rounded-t-[12px] gap-[20px]">
          <span className="border-t-[1px] border-primary-main"></span>
        </div>
      </div>
    </DashboardLayout>
  );
};
