import { ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import { onSubmitResetPass } from "../../redux/actionCreators/ResetPass";
import { ResetPasswordForm } from "../../sections/ResetPassword";
import { ResetPassModel } from "../../types/models/ResetPass";
import { useFormFieldsHandleChange } from "../../utils";

export const ResetPassword = (): ReactElement => {
  const navigate = useNavigate();

  //State and Handlers
  const [resetPassInfo] = useState<ResetPassModel>({ email: "", newPassword: "", confirmPassword: "" });
  const [resetPassInfoInputs, handleResetPassInfoChange] = useFormFieldsHandleChange(resetPassInfo);
  const [errorsResetPassInfo, setErrorsResetPassInfo] = useState({
    email: { error: false, message: "" },
    newPassword: { error: false, message: "" },
    confirmPassword: { error: false, message: "" },
  });

  const validateErrorResetPassInfo = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!e.target.value) {
      setErrorsResetPassInfo({ ...errorsResetPassInfo, [e.target.name]: { error: true, message: "" } });
    } else {
      setErrorsResetPassInfo({ ...errorsResetPassInfo, [e.target.name]: { error: false, message: "" } });
    }
  };

  const validateFormResetPassInfo = () => {
    let formIsValid = true;
    let localerrorsResetPassInfo = errorsResetPassInfo;

    if (resetPassInfoInputs.newPassword === "") {
      formIsValid = false;
      localerrorsResetPassInfo.newPassword = { error: true, message: "New Password is Required!" };
    }

    if (resetPassInfoInputs.confirmPassword === "") {
      formIsValid = false;
      localerrorsResetPassInfo.confirmPassword = { error: true, message: "Confirm Password is Required!" };
    }

    setErrorsResetPassInfo({ ...errorsResetPassInfo, ...localerrorsResetPassInfo });
    return formIsValid;
  };

  const onSubmit = async () => {
    if (!validateFormResetPassInfo()) return;

    if (resetPassInfoInputs.newPassword === resetPassInfoInputs.confirmPassword) {
      //update pass
      const email = window.location.href.split("=")[1];
      resetPassInfoInputs.email = email;
      let res = await onSubmitResetPass(resetPassInfoInputs);
      if (res.response === "Success") {
        alert("Password Updated Successfully.");
        navigate("/");
      }
    } else {
      setErrorsResetPassInfo({ ...errorsResetPassInfo, confirmPassword: { error: true, message: "New password and confirm password is not same." } });
    }
  };

  return (
    <div className="flex gap-[40px] w-full">
      <div className="hidden md:block md:w-[55%] md:relative ">
        <div className="w-full h-[100vh]">
          <img src={`${process.env.PUBLIC_URL}/imgs/undraw_forgot_password.png`} alt="Vaccifi" className="object-cover fixed w-[55%] h-full" />
        </div>
      </div>
      <div className="w-full p-[20px] flex justify-center items-center md:w-[45%]">
        <div className="w-[380px]">
          <div>
            <img src={`${process.env.PUBLIC_URL}/imgs/va-logo-with-text.svg`} alt="vaccine text" />
            <h2 className="text-[25px] font-[600] text-[#0F5175]">Reset Password</h2>
          </div>
          <div className="mt-[30px]">
            <ResetPasswordForm
              inputs={resetPassInfoInputs}
              errorsResetPassInfo={errorsResetPassInfo}
              validateErrorResetPassInfo={validateErrorResetPassInfo}
              onChange={handleResetPassInfoChange}
              onSubmit={onSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
