import { Box, Modal } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { ReactElement, useState } from "react";
import { AuthenticateAccount } from "../../icons/AuthenticateAccount.icon";
import { VAButton } from "../VAButton/VAButton.component";

interface ModalProps {
  open: boolean;
  handleClose: any;
  patientInfoInputs: any;
  onSubmitVerificationCode: any;
  onClickResendCode: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderStyle: "none",
  borderRadius: "43px",
  boxShadow: 24,
};

export const AuthenticationModal = ({ open, handleClose, patientInfoInputs, onSubmitVerificationCode, onClickResendCode }: ModalProps): ReactElement => {
  const [value, setValue] = useState("");

  const handleChange = (newValue: any) => {
    setValue(newValue);
    patientInfoInputs.verificationCode = newValue;
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div className="flex flex-col justify-center items-center w-[350px] px-[20px] md:w-[500px] md:px-[60px] py-[20px] ">
            <div>
              <AuthenticateAccount />
            </div>
            <div className="max-w-[360px] flex flex-col gap-[22px] mt-[17px]">
              <h2 className="text-[20px] font-[600] text-[#000000]">Authenticate your account</h2>
              <p className="text-[16px] font-[400] text-[#1F2124]">A 6 digit verification code has been sent on your email. This code will be valid for 15 min.</p>
            </div>
            <div className="mt-[28px]">
              <MuiOtpInput length={6} value={value} onChange={handleChange} />
            </div>
            <div className="flex flex-col gap-[20px] mt-[28px] w-full">
              <VAButton text="Verify" dark onClick={onSubmitVerificationCode} />
              <VAButton text="Resend Code" onClick={onClickResendCode} outlined />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
