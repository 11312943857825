import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Modal } from "@mui/material";
import { ReactElement, useState } from "react";
import { VAButton } from "../VAButton/VAButton.component";
import { shareRecord } from "../../redux/actionCreators";
import { FormInput } from "../FormFields";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderStyle: "none",
  borderRadius: "43px",
  boxShadow: 24,
};

interface ModalProps {
  open: boolean;
  setOpen: any;
  shareClick: any;
  data: any;
  setAlertMessage: any;
}

export const ShareEmailModal = ({ open, setOpen, shareClick, data, setAlertMessage }: ModalProps): ReactElement => {
  const [confirmCheck, setConfirmCheck] = useState(false);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({ confirmCheck: { error: false, message: "" }, email: { error: false, message: "" } });

  const handleChange = async (event: any) => {
    if (event.target.type === "checkbox") {
      const target = event.target as HTMLInputElement;
      setConfirmCheck(target.checked);
    } else {
      if (event.target.name === "email") {
        setEmail(event.target.value as string);
      }
    }
  };
  const validateError = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!e.target.value) {
      setErrors({ ...errors, [e.target.name]: { error: true, message: "Required Field!" } });
    } else {
      setErrors({ ...errors, [e.target.name]: { error: false, message: "" } });
    }
  };
  const validateForm = () => {
    let formIsValid = true;
    let localErrors = { confirmCheck: { error: false, message: "" } };

    if (confirmCheck === false) {
      formIsValid = false;
      localErrors.confirmCheck = { error: true, message: "Consent is required to proceed." };
    }
    setErrors({ ...errors, ...localErrors });
    return formIsValid;
  };
  const onClickShare = async () => {
    if (validateForm()) {
      try {
        data.receiver_email = email;
        const res = await shareRecord(data);
        setAlertMessage({ type: "success", description: res?.message, open: true });
      } catch (error) {
        console.error("Error in getShareVaccineRecords:", error);
      }
      shareClick(data);
      setOpen(false);
    }
  };
  return (
    <div>
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div className="flex flex-col justify-center items-center w-[350px] px-[20px] md:w-[500px] md:px-[60px] py-[20px] ">
            <div className="max-w-[360px] flex flex-col gap-[22px] mt-[17px]">
              <h2 className="text-[20px] font-[600] text-[#000000]">Enter email to share</h2>
            </div>
            <div className="flex flex-col gap-[20px] mt-[28px] w-full">
              <FormInput
                name="email"
                label="Email"
                type="email"
                value={email}
                validate={{ error: errors.email.error, message: errors.email.message }}
                required
                onChange={handleChange}
                onBlur={validateError}
              />
              <FormControl required error={errors.confirmCheck.error} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={confirmCheck} onChange={handleChange} name="confirmCheck" />}
                    label={<span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] pt-[6px]">I understand Vaccifi cannot be liable for information shared.</span>}
                    style={{ border: "none", padding: 0 }}
                  />
                </FormGroup>
                <FormHelperText>{errors.confirmCheck.message}</FormHelperText>
              </FormControl>
              <VAButton text="Send" dark onClick={onClickShare} />
              <VAButton text="No" onClick={() => setOpen(false)} outlined />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
