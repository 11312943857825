import { categoriesModel, vaccinesModel } from "../models/scheduleVaccine";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_VACCINES = "GET_VACCINES";

export interface getCategoriesAction {
  type: typeof GET_CATEGORIES;
  payload: categoriesModel;
}

export interface getVaccineAction {
  type: typeof GET_VACCINES;
  payload: vaccinesModel;
}

export type ScheduleVaccineActionTypes = getCategoriesAction | getVaccineAction;
