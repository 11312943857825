import { ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import { VAButton } from "../../components";
import { Back } from "../../icons";
import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Dialog, IconButton, Link, Toolbar, Typography } from "@mui/material";
import FileViewer from "../../components/FileViewer/FileViewer";
export const TermsCondition = (): ReactElement => {
  const [visModalOpen, setVISModalOpen] = useState(false);
  const navigate = useNavigate();
  const handleAgree = () => {
    navigate("/create-profile-welcome");
  };

  const ViewTermsAndConditions = () => {
    return (
      <Dialog fullScreen open={visModalOpen} onClose={() => setVISModalOpen(false)}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => setVISModalOpen(false)} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {"Vaccifi Terms and Conditions"}
            </Typography>
          </Toolbar>
        </AppBar>

        <FileViewer filePath={process.env.REACT_APP_DOCUMENT_URL + "Vaccifi Terms and Conditions.pdf"} fileType="pdf" />
      </Dialog>
    );
  };

  return (
    <div className="flex gap-[40px] w-full">
      <div className="hidden md:block md:w-[50%] md:relative ">
        <div className="w-full h-[100vh]">
          <img src={`${process.env.PUBLIC_URL}/imgs/undraw_terms.png`} alt="Vaccifi" className="object-cover fixed w-[55%] h-full" />
        </div>
      </div>
      <div className="w-full p-[20px]  flex justify-center items-center md:w-[50%]">
        <div>
          <div className="flex items-center gap-[5px] cursor-pointer">
            <Back />
            <h2 className="text-[30px] font-[600] text-[#0F5175] mt-[20px] mb-[30px]">Terms and conditions</h2>
          </div>
          <div>
            <span className="text-justify">
              Please review the
              <Link sx={{ marginLeft: 1, marginBottom: 1 }} component="button" variant="body2" onClick={() => setVISModalOpen(true)}>
                Vaccifi Terms & Conditions
              </Link>
            </span>
          </div>
          <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px]">
            <VAButton text="Decline" outlined />
            <VAButton text="Agree" dark onClick={handleAgree} />
          </div>
        </div>
      </div>
      <ViewTermsAndConditions />
    </div>
  );
};
