import { ReactElement, useEffect, useState } from "react";
import "./CreateProfile.styles.scss";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Dayjs } from "dayjs";
import { IState, State, City, ICity } from "country-state-city";
import { VAButton, CustomStepper, CustomTags, FromSelect } from "../../components";
import { onCreateProfile } from "../../redux/actionCreators";
import { AppState } from "../../redux/store";
import { Back } from "../../icons";
import { CreateProfileModel } from "../../types/models/CreateProfile";
import { AppBar, Dialog, IconButton, Toolbar, Typography, Link as MUILink, FormControlLabel, FormControl, FormGroup, Checkbox, FormHelperText } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileViewer from "../../components/FileViewer/FileViewer";
import { CreateProfileSection } from "../../sections/CreateProfile";

export const CreateProfile = (): ReactElement => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(1);
  const [steps] = useState(["User Info", "Health Conditions", "HIPPA agreement"]);

  const { userData } = useSelector<AppState, AppState["Login"]>(({ Login }) => Login);
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  // for step 1
  const [selectedHealthConditions, setSelectedHealthConditions] = useState<any>([]);

  const onClickCancel_health = (text: string) => {
    const updatedHealthConditions = selectedHealthConditions.filter((item: any) => item.value !== text);
    setSelectedHealthConditions(updatedHealthConditions);
  };

  const handleSubmit_health = (value: string) => {
    let newListHealth = createProfileInputs.health_condition_list.filter((x: any) => x.value === value);
    // Check if the item already exists in the selectedHealthConditions array
    if (!selectedHealthConditions.some((item: any) => item.value === newListHealth[0].value)) {
      setSelectedHealthConditions([...selectedHealthConditions, newListHealth[0]]);
    }
  };

  // Business Info State and Handlers
  const [createProfileInputs, setCreateProfileInputs] = useState<CreateProfileModel>({
    first_name: "",
    last_name: "",
    email: userData.email,
    ph_no: "",
    gender: "",
    dob: "",
    address: "",
    state: null,
    city: null,
    hic: "",
    phar_bc: "",
    health_condition: "",
    health_condition_list: [
      { value: "1", label: "High Bd Pressure" },
      { value: "2", label: "Cholesterol" },
      { value: "3", label: "Obesity" },
      { value: "4", label: "Diabetes" },
      { value: "5", label: "Asthma" },
      { value: "6", label: "Cancer" },
    ],
  });

  const [dateValue, setDateValue] = useState<Dayjs | null>(null);
  const [states, setStates] = useState<IState[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);

  const [errorsCreateProfile, seterrorsCreateProfile] = useState({
    first_name: { error: false, message: "" },
    last_name: { error: false, message: "" },
    email: { error: false, message: "" },
    ph_no: { error: false, message: "" },
    gender: { error: false, message: "" },
    dob: { error: false, message: "" },
    address: { error: false, message: "" },
    city: { error: false, message: "" },
    state: { error: false, message: "" },
  });
  const handleReleaseInfoChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    const updatedCreateProfileInputs: CreateProfileModel = { ...createProfileInputs };
    (updatedCreateProfileInputs as any)[name] = value;
    setCreateProfileInputs(updatedCreateProfileInputs);
  };
  const handleAutoComplete = (event: React.SyntheticEvent, value: any | [], name: any) => {
    // Create a copy of the current businessInfo
    const updatedCreateProfileInputs: CreateProfileModel = { ...createProfileInputs };

    // Use type assertion to tell TypeScript that `name` is a valid key
    (updatedCreateProfileInputs as any)[name] = value;

    // Update the state with the modified businessInfo
    setCreateProfileInputs(updatedCreateProfileInputs);
    if (name === "state") {
      if (value === null) {
        updatedCreateProfileInputs.city = null;
        setCities([]); // Assuming setCities is a state updater for cities, reset it to an empty array when state is null
        return; // Exit the function early
      }
      updatedCreateProfileInputs.city = null;
      const cities = City.getCitiesOfState("US", value.isoCode);
      setCities(cities);
    }
  };
  const validateErrorCreateProfile = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!e.target.value) {
      seterrorsCreateProfile({ ...errorsCreateProfile, [e.target.name]: { error: true, message: "" } });
    } else {
      seterrorsCreateProfile({ ...errorsCreateProfile, [e.target.name]: { error: false, message: "" } });
    }
  };
  const validateFormReleaseInfo = () => {
    let formIsValid = true;
    let localerrorsCreateProfile = {
      first_name: { error: false, message: "" },
      last_name: { error: false, message: "" },
      email: { error: false, message: "" },
      ph_no: { error: false, message: "" },
      gender: { error: false, message: "" },
      dob: { error: false, message: "" },
      address: { error: false, message: "" },
      city: { error: false, message: "" },
      state: { error: false, message: "" },
    };

    if (createProfileInputs.first_name === "") {
      formIsValid = false;
      localerrorsCreateProfile.first_name = { error: true, message: "Legal first name is required!" };
    }
    if (createProfileInputs.first_name.length < 3) {
      formIsValid = false;
      localerrorsCreateProfile.first_name = { error: true, message: "Lefal first name should be of more than 3 characters!" };
    }

    if (createProfileInputs.last_name === "") {
      formIsValid = false;
      localerrorsCreateProfile.last_name = { error: true, message: "Last name is required!" };
    }
    if (createProfileInputs.last_name.length < 3) {
      formIsValid = false;
      localerrorsCreateProfile.last_name = { error: true, message: "Last name should be of more than 3 characters!" };
    }
    if (createProfileInputs.email === "") {
      formIsValid = false;
      localerrorsCreateProfile.email = { error: true, message: "Email is required!" };
    } else if (!emailPattern.test(createProfileInputs.email)) {
      formIsValid = false;
      localerrorsCreateProfile.email = { error: true, message: "Invalid email!" };
    }
    if (createProfileInputs.ph_no === "") {
      formIsValid = false;
      localerrorsCreateProfile.ph_no = { error: true, message: "Phone number is required!" };
    }
    if (createProfileInputs.gender === "") {
      formIsValid = false;
      localerrorsCreateProfile.gender = { error: true, message: "Gender is required!" };
    }

    if (dateValue === null) {
      formIsValid = false;
      localerrorsCreateProfile.dob = { error: true, message: "Date of birth is required!" };
    }
    if (createProfileInputs.address === "") {
      formIsValid = false;
      localerrorsCreateProfile.address = { error: true, message: "Address is required!" };
    }
    if (createProfileInputs.city === "") {
      formIsValid = false;
      localerrorsCreateProfile.city = { error: true, message: "City is required!" };
    }
    if (createProfileInputs.state === "") {
      formIsValid = false;
      localerrorsCreateProfile.state = { error: true, message: "State is required!" };
    }
    seterrorsCreateProfile({ ...errorsCreateProfile, ...localerrorsCreateProfile });
    return formIsValid;
  };
  const onNextCreateProfile = async () => {
    if (activeStep === 0 && !validateFormReleaseInfo()) return;
    setActiveStep(activeStep + 1);
  };

  // Step 3
  const [visModalOpen, setVISModalOpen] = useState(false);
  const [conditionCheck, setConditionCheck] = useState(false);
  const [errors, setErrors] = useState({ conditionCheck: { error: false, message: "" } });

  const handleChange = (event: any) => {
    if (event.target.type === "checkbox") {
      const target = event.target as HTMLInputElement;
      if (event.target.name === "conditionCheck") {
        setConditionCheck(target.checked);
      }
    }
  };
  const validateHIPPAForm = () => {
    let formIsValid = true;
    let localErrors = {
      conditionCheck: { error: false, message: "" },
    };
    if (conditionCheck === false) {
      formIsValid = false;
      localErrors.conditionCheck = { error: true, message: "Please check the condition!" };
    }
    setErrors({ ...errors, ...localErrors });
    return formIsValid;
  };
  const onSubmitCreateProfileInfo = async () => {
    if (!validateHIPPAForm()) return;
    try {
      const signupData = { password: userData.password };
      let data = { profileData: createProfileInputs, healthConditions: selectedHealthConditions, signupData };

      let res = await onCreateProfile(data);
      if (res.status === "SUCCESS") navigate("/final-welcome");
    } catch (err) {
      console.log("Create profile error: ", err);
    }
  };

  useEffect(() => {
    const states = State.getStatesOfCountry("US");
    setStates(states);
    setActiveStep(0);
  }, []);
  const ViewHIPPAAgreement = () => {
    return (
      <Dialog fullScreen open={visModalOpen} onClose={() => setVISModalOpen(false)}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => setVISModalOpen(false)} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {"HIPPA agreement"}
            </Typography>
          </Toolbar>
        </AppBar>

        <FileViewer filePath={process.env.REACT_APP_DOCUMENT_URL + "HIPAA Agreement Vaccifi.pdf"} fileType="pdf" />
      </Dialog>
    );
  };
  return (
    <div className="outer">
      <div className="outer__left">
        <div className="outer__left-main-img">
          <img src={`${process.env.PUBLIC_URL}/imgs/sider-image.png`} alt="Vaccifi" />
        </div>
        <div className="outer__left-secondary-img">
          <img src={`${process.env.PUBLIC_URL}/imgs/sider-text-image.svg`} alt="vaccine text" />
        </div>
      </div>
      <div className="outer__right overflow-y-auto">
        <div className="outer__right-stepper">
          <CustomStepper steps={steps} activeStep={activeStep} />
          <div className="flex items-center gap-[5px] cursor-pointer mt-[13px] mb-[8px]">
            <Back />
            {activeStep === 0 ? (
              <Link to="/create-profile-welcome" className="text-[16px] font-[600] text-[#888A8C]">
                Back
              </Link>
            ) : (
              <Link to="#" className="text-[16px] font-[600] text-[#888A8C]" onClick={() => setActiveStep(activeStep - 1)}>
                Back
              </Link>
            )}
          </div>
          <div className="outer__right-header">
            {activeStep === 1 ? (
              <div>
                <h1 className="outer__right-header-heading">Select health conditions</h1>
                <p className="text-[14px] font-[400] text-[rgba(50,53,57,0.4)]">Please select conditions which do you have below this selection.</p>
              </div>
            ) : activeStep === 2 ? (
              <h1 className="outer__right-header-heading">HIPPA Agreement</h1>
            ) : null}
          </div>
          {activeStep === 0 ? (
            <CreateProfileSection
              dateValue={dateValue}
              states={states}
              cities={cities}
              setDateValue={setDateValue}
              inputs={createProfileInputs}
              errorsCreateProfile={errorsCreateProfile}
              validateErrorCreateProfile={validateErrorCreateProfile}
              onChange={handleReleaseInfoChange}
              handleAutoComplete={handleAutoComplete}
            />
          ) : activeStep === 1 ? (
            <div className="grid grid-cols-1 items-center gap-[25px]">
              <div className="mt-[12px] grid grid-cols-3 gap-2">
                {selectedHealthConditions.map((item: any) => {
                  return <CustomTags text={item.label} onClickCancel={onClickCancel_health} />;
                })}
              </div>
              <div className="mt-[20px]">
                <div className="grid grid-cols-[1fr_1fr] items-center gap-[50px]">
                  <FromSelect
                    name="Which conditions do you have ?"
                    label="Chronic Conditions"
                    value={createProfileInputs.health_condition}
                    required
                    onChange={handleReleaseInfoChange}
                    onBlur={validateErrorCreateProfile}
                    options={createProfileInputs.health_condition_list}
                    onSubmit={handleSubmit_health}
                  />
                </div>
              </div>
            </div>
          ) : activeStep === 2 ? (
            <div className="grid grid-cols-1 items-center gap-[25px]">
              <span className="text-justify">
                Please review the
                <MUILink sx={{ marginLeft: 1, marginBottom: 1 }} component="button" variant="body2" onClick={() => setVISModalOpen(true)}>
                  HIPPA agreement
                </MUILink>
                before finishing the steps
              </span>

              <div className="flex gap-[12px]">
                <FormControl required error={errors.conditionCheck.error} component="fieldset" variant="standard">
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={conditionCheck} onChange={handleChange} name="conditionCheck" />}
                      label={
                        <span className="text-[14px] font-[400] text-[#888A8C] max-w-[300px] pt-[20px]">
                          By checking this box, I verify that the HIPAA agreement has been received and reviewed, and I agree to the information contained within.
                        </span>
                      }
                      style={{ border: "none", padding: 0 }}
                    />
                  </FormGroup>
                  <FormHelperText>{errors.conditionCheck.message}</FormHelperText>
                </FormControl>
              </div>
            </div>
          ) : null}
          <div className="mt-[40px] flex grid grid-cols-2 mb-[20px]">
            {activeStep === 0 ? (
              <VAButton text="Next" light onClick={onNextCreateProfile} />
            ) : activeStep === 1 ? (
              <VAButton text="Next" light onClick={onNextCreateProfile} />
            ) : (
              <div className="grid grid-cols-2 items-center gap-[50px]">
                <VAButton text="Create" dark onClick={onSubmitCreateProfileInfo} />
              </div>
            )}
          </div>
        </div>
      </div>
      <ViewHIPPAAgreement />
    </div>
  );
};
