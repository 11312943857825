import { ReactElement } from "react";
import { DashboardLayout } from "../../components";

export const Profile = (): ReactElement => {
  return (
    <DashboardLayout>
      <h1>Under Development</h1>
    </DashboardLayout>
  );
};
