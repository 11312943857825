import { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DashboardCard, DashboardLayout } from "../../components";
import { getDueVacNotification } from "../../redux/actionCreators";
import { AppState } from "../../redux/store";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import { Grid } from "@mui/material";
import { ScheduleVaccineIcon } from "../../icons/dashboard/ScheduleVaccineIcon.icon";
import { ViewVaccineAppointmentsIcon } from "../../icons/dashboard/ViewVaccineAppointmentsIcon.icon";
import { ViewVaccineCardIcon } from "../../icons/dashboard/ViewVaccineCardIcon.icon";
import { AddVaccineRecordIcon } from "../../icons/dashboard/AddVaccineRecordIcon.icon";
import { ShareVaccineRecordIcon } from "../../icons/dashboard/ShareVaccineRecordIcon.icon";
import { DiagnosticTestIcon } from "../../icons/dashboard/DiagnosticTestIcon.icon";
import { ReportVaccineAdverseEventIcon } from "../../icons/dashboard/ReportVaccineAdverseEventIcon.icon";

export const Home = (): ReactElement => {
  const [dueVacNotif, setDueVacNotif] = useState([]);
  const { userData } = useSelector<AppState, AppState["Login"]>(({ Login }) => Login);

  useEffect(() => {
    const getData = async () => {
      let data = {
        patient_email: userData.userData.email,
      };
      const res = await getDueVacNotification(data);
      setDueVacNotif(res.data);
    };
    getData();
  }, [userData, setDueVacNotif]);
  const currentHour = new Date().getHours();
  return (
    <div>
      <DashboardLayout>
        <div>
          <h2 className="text-[24px] text-primary-main font-[600]">{currentHour < 12 ? "Good Morning!" : currentHour < 18 ? "Good Afternoon!" : "Good Evening!"}</h2>
        </div>
        {dueVacNotif.map((item: any) => {
          const timeslot = item.date + item.slot;
          return (
            <DashboardCard>
              <br></br>
              <h2 style={{ margin: "10px" }}>
                <VaccinesIcon style={{ color: "#F48744" }} /> Hi {item.patient_name}! You are due for the {item.vaccine_name} vaccine on time {timeslot}.
              </h2>
              <br></br>
            </DashboardCard>
          );
        })}
        <Grid container spacing={2}>
          <Grid item xs={2} sm={4} md={4} key={1}>
            <ScheduleVaccineIcon />
          </Grid>
          <Grid item xs={2} sm={4} md={4} key={2}>
            <DiagnosticTestIcon />
          </Grid>
          <Grid item xs={2} sm={4} md={4} key={3}>
            <ViewVaccineAppointmentsIcon />
          </Grid>
          <Grid item xs={2} sm={4} md={4} key={4}>
            <ViewVaccineCardIcon />
          </Grid>
          <Grid item xs={2} sm={4} md={4} key={5}>
            <AddVaccineRecordIcon />
          </Grid>
          <Grid item xs={2} sm={4} md={4} key={6}>
            <ShareVaccineRecordIcon />
          </Grid>
          <Grid item xs={2} sm={4} md={4} key={7}>
            <ReportVaccineAdverseEventIcon />
          </Grid>
        </Grid>
      </DashboardLayout>
    </div>
  );
};
