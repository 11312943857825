import { ReactElement } from "react";
import { VAButton, FormInput } from "../../components";
import { ErrorsPatientInfoModel, PatientInfoModel } from "../../types/models/Patient";

interface patientInfoProps {
  inputs: PatientInfoModel;
  errorsPatientInfo: ErrorsPatientInfoModel;
  validateErrorPatientInfo: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onSubmitSignUp: any;
}

export const SignUpForm = ({ inputs, errorsPatientInfo: errors, validateErrorPatientInfo: validateError, onChange, onSubmitSignUp }: patientInfoProps): ReactElement => {
  return (
    <>
      <div className="flex flex-col gap-[30px]">
        <FormInput
          name="email"
          label="Email"
          type="email"
          value={inputs.email}
          validate={{ error: errors.email.error, message: errors.email.message }}
          required
          onChange={onChange}
          onBlur={validateError}
        />
        <FormInput
          name="password"
          label="Password"
          type="password"
          value={inputs.password}
          validate={{ error: errors.password.error, message: errors.password.message }}
          required
          onChange={onChange}
          onBlur={validateError}
        />

        <FormInput
          name="confirm_password"
          label="Confirm Password"
          type="password"
          value={inputs.confirm_password}
          validate={{ error: errors.confirm_password.error, message: errors.confirm_password.message }}
          required
          onChange={onChange}
          onBlur={validateError}
        />

        <div>
          <VAButton text="Sign Up" dark onClick={onSubmitSignUp} />
        </div>
      </div>
    </>
  );
};
