import { AppActions } from "../../types";
import { SEND_LOGIN_DATA, SET_USER_DATA } from "../../types/actions/Login";
import { LoginModel } from "../../types/models/Login";

const initialState: {
  loginData: LoginModel;
  userData: any;
} = {
  loginData: {
    email: "",
    password: "",
  },
  userData: {
    first_name: "",
    last_name: "",
    name: "",
    email: "",
    phone_no: "",
  },
};
const CreateProfile = (state = initialState, action: AppActions) => {
  switch (action.type) {
    case SEND_LOGIN_DATA:
      return {
        ...state,
        loginData: action.payload,
      };
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};
export default CreateProfile;
