import { Box, Modal } from "@mui/material";
import { ReactElement } from "react";
import { VAButton } from "../VAButton/VAButton.component";
import { useLocation, useNavigate } from "react-router-dom";
interface ModalProps {
  open: boolean;
  setOpen: any;
  slot: any;
  date: any;
  loc: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  borderStyle: "none",
  borderRadius: "43px",
  boxShadow: 24,
};

export const PrePaperWorkModal = ({ open, setOpen, slot, date, loc }: ModalProps): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div>
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div className="flex flex-col justify-center items-center w-[350px] px-[20px] md:w-[500px] md:px-[60px] py-[20px] ">
            <div className="max-w-[360px] flex flex-col gap-[22px] mt-[17px]">
              <h2 className="text-[20px] font-[600] text-[#000000]">
                All your pre-appointment paperwork is done. See you at {slot}, {date}, in {loc}.
              </h2>
            </div>
            <div className="flex flex-col gap-[20px] mt-[28px] w-full">
              <VAButton
                text="OK"
                onClick={() => {
                  setOpen(false);
                  navigate(`${location.pathname === "/fill-details" ? "/schedule-vaccines" : "/schedule-tests"}`);
                }}
                outlined
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
