import jwtAxios from "../../services/auth/jwt-auth";

export const getVaccineCategory = async () => {
  try {
    const res = await jwtAxios.post("/scheduleRouter/getvaccinecategories", {});
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const getTestCategory = async () => {
  try {
    const res = await jwtAxios.post("/scheduleRouter/gettestcategories", {});
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getVaccine = async (data: any) => {
  try {
    const res = await jwtAxios.post("/scheduleRouter/getVaccine", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getLocationData = async (data: any) => {
  try {
    const res = await jwtAxios.post("/scheduleRouter/getLocationData", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getScheduledData = async (data: any) => {
  try {
    const res = await jwtAxios.post("/scheduleRouter/getScheduledData", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const bookAppointment = async (data: any) => {
  try {
    const res = await jwtAxios.post("/scheduleRouter/bookAppointment", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getScheduledVaccineData = async (data: any) => {
  try {
    const res = await jwtAxios.post("/scheduleRouter/getScheduledVaccineData", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteSelectedRecord = async (data: any) => {
  try {
    const res = await jwtAxios.post("/scheduleRouter/deleteselectedrecord", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const rescheduleAppointment = async (data: any) => {
  try {
    const res = await jwtAxios.post("/scheduleRouter/rescheduleAppointment", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getVaccineScreeningData = async (data: any) => {
  try {
    const res = await jwtAxios.post("/scheduleRouter/getVaccineScreeningData", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const saveScreeningQuizAnswers = async (data: any) => {
  try {
    const res = await jwtAxios.post("/scheduleRouter/savescreeningquizanswers", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getDueVacNotification = async (data: any) => {
  try {
    const res = await jwtAxios.post("/scheduleRouter/getDueVacNotification", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getVISDocument = async (data: any) => {
  try {
    const res = await jwtAxios.post("/scheduleRouter/getvisdocument", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const viewPaperwork = async (data: any) => {
  try {
    const res = await jwtAxios.post("/scheduleRouter/viewpaperwork", data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
