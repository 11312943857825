import { Box, Modal, Typography } from "@mui/material";
import { ReactElement } from "react";
import { VAButton } from "../VAButton/VAButton.component";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import AccessAlarmsOutlinedIcon from "@mui/icons-material/AccessAlarmsOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { getFormattedDate } from "../../shared/Util";

interface ModalProps {
  open: boolean;
  setOpen: (a: boolean) => void;
  appointmentModalData: any;
}

const style = {
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
  marginTop: "10px",
};

export const AppointmentModal = ({ open = false, setOpen, appointmentModalData }: ModalProps): ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleOk = async () => {
    try {
      setOpen(false);
      navigate(
        `${location.pathname === "/ScheduleAppointment" ? "/fill-details" : "/fill-test-details"}?v=${appointmentModalData.vaccine_id}&biz_branch_id=${appointmentModalData.biz_branch_id}&sa=${
          appointmentModalData.schedule_appointment_id
        }&t=${appointmentModalData.slot}&loc=${appointmentModalData.prov_location}&date=${appointmentModalData.date}`
      );
    } catch (err) {
      console.log("err: ", err);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        style={{
          display: "flex",
          alignItems: "center",
          overflowY: "scroll",
          justifyContent: "center",
        }}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="">
            <div className="flex justify-center">
              <h2 className="text-[16px] md:text-[20px] text-[#000000] font-[600] text-center">Your appointment for {appointmentModalData.vaccine} has been scheduled!</h2>
            </div>
            <div className="mt-[10px]">
              <Box display="flex" style={{ display: "flex", flexWrap: "nowrap", width: "100%", alignItems: "center", justifyContent: "center" }}>
                <CalendarTodayOutlinedIcon style={{ color: "#F48744" }} />
                <Typography variant="subtitle1">{open === true ? appointmentModalData.date : null}</Typography>
                <Box m={1} />
                <AccessAlarmsOutlinedIcon style={{ color: "#F48744" }} />
                <Typography variant="subtitle1">{open === true ? appointmentModalData.slot : null}</Typography>
                <Box m={1} />
                <LocationOnOutlinedIcon style={{ color: "#F48744" }} />
                <Typography variant="subtitle1">{appointmentModalData.provider_biz_name}</Typography>
                <Box m={1} />
              </Box>
            </div>
            <div className="mt-[10px] flex justify-end cursor-pointer" style={{ justifyContent: "center" }}>
              <VAButton outlined text="OK" onClick={handleOk} />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
