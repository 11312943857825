export const getFormattedDate = (date: any) => {
  if (!date) return "";
  var dateObj = new Date(date);
  var day = dateObj.getDate().toString();
  if (day.length < 2) day = "0" + day;
  const month = new Intl.DateTimeFormat("en", { month: "short" }).format(dateObj);
  const year = dateObj.getFullYear();
  return `${day} ${month} ${year}`;
};

// Start Timeslots Methods
export function createTimeSlots(scheduleData: any[]): { vaccine_schedule_id: any; slot: string }[] {
  const slots: { vaccine_schedule_id: any; slot: string }[] = [];

  for (const data of scheduleData) {
    const { id, start_time, end_time, slot_duration } = data;

    const start = parseTime(start_time);
    const end = parseTime(end_time);
    const duration = parseInt(slot_duration, 10);

    let currentTime = start;

    while (currentTime < end) {
      slots.push({ vaccine_schedule_id: id, slot: formatTime(currentTime) });
      currentTime = addMinutes(currentTime, duration);
    }
  }
  // Remove duplicate slots
  const uniqueSlots = slots.filter((slot, index, arr) => {
    return index === arr.findIndex((s) => s.vaccine_schedule_id === slot.vaccine_schedule_id && s.slot === slot.slot);
  });

  return uniqueSlots;
}

function parseTime(time: string): Date {
  const [timeString, period] = time.split(" ");
  const [hours, minutes] = timeString.split(":");
  const parsedTime = new Date();
  let parsedHours = parseInt(hours, 10);

  if (period === "PM" && parsedHours !== 12) {
    parsedHours += 12;
  } else if (period === "AM" && parsedHours === 12) {
    parsedHours = 0;
  }

  parsedTime.setHours(parsedHours, parseInt(minutes, 10), 0);
  return parsedTime;
}

function formatTime(time: Date): string {
  const hours = time.getHours();
  const minutes = time.getMinutes();
  const period = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes.toString().padStart(2, "0");
  return `${formattedHours}:${formattedMinutes} ${period}`;
}

function addMinutes(time: Date, minutes: number): Date {
  const result = new Date(time.getTime() + minutes * 60000);
  return result;
}
// End Timeslots Methods
