import React, { ReactElement, useEffect, useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { Link } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import { Card, Checkbox } from "@mui/material";
import { VAButton, CustomStepper, DashboardLayout, FormInput, PrePaperWorkModal, VADatePicker } from "../../components";
import { Back } from "../../icons";
import { useLocation } from "react-router-dom";
import { getVaccineScreeningData, saveScreeningQuizAnswers } from "../../redux/actionCreators";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/store";
import { AlertType } from "../../shared/constants/AppConst";
import VAAlert from "../../components/Alert/Alert";

export const FillTestDetails = (): ReactElement => {
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState<any>([]);
  const location = useLocation();
  const [selectedVacScrData, setSelectedVacScrData] = useState<any>([]);
  const [selectedVacData, setSelectedVacData] = useState<any>([]);
  const [ddOpen, setddOpen] = useState<boolean>(false);
  // const [prev, setPrev] = useState<boolean>(false);
  const [selectedDropdown, setSelectedDropdown] = useState<string | undefined>(undefined);
  const [checkedConsentTerms, setCheckedConsentTerms] = useState<boolean | undefined>(false);
  const [open, setOpen] = useState(false);
  const [schedule_appointment_id, setScheduleAppointmentId] = useState<string | null>();
  const [signature, setSignature] = useState("");
  const [dateOfSignature, setDateOfSignature] = useState<Dayjs | null>(dayjs());
  const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);
  //user email
  const { userData } = useSelector<AppState, AppState["Login"]>(({ Login }) => Login);

  const onChange = (e: any) => {
    if (e.target.name === "signature") setSignature(e.target.value);
    if (e.target.name === "dateOfSignature") setDateOfSignature(e.target.value);
  };

  const handleStepOne = async () => {
    let allChecked = true;
    for (let i = 0; i < selectedVacScrData.length; i++) {
      let array = selectedVacScrData[i].screening_questions;
      for (let j = 0; j < array.length; j++) {
        let optionsArray = array[j].options;
        if (optionsArray[0].isChecked === false && optionsArray[1].isChecked === false && optionsArray[2].isChecked === false) {
          allChecked = false;
          break;
        }
      }
    }
    if (allChecked === true) {
      setActiveStep(1);
    } else {
      setAlertMessage({ type: "error", description: "Please answer all the questions", open: true });
    }
  };

  const handleFinalStep = async () => {
    let proceed = true;
    if (dateOfSignature === null || checkedConsentTerms === false || signature === "") {
      proceed = false;
    }

    if (proceed === true) {
      const searchParams = new URLSearchParams(location.search);
      let data = {
        patient_email: userData.email,
        schedule_appointment_id: schedule_appointment_id,
        biz_branch_id: searchParams.get("biz_branch_id"),
        screening_data: selectedVacScrData,
        signature,
        date_of_signature: dateOfSignature?.toDate().toUTCString(),
        type: "TEST",
      };
      let res = await saveScreeningQuizAnswers(data);
      setAlertMessage({ type: "success", description: res.message, open: true });
      setOpen(true);
    } else {
      setAlertMessage({ type: "error", description: "Please fill all fields and accept the consent", open: true });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedConsentTerms(!checkedConsentTerms);
  };

  const onCheckBoxClick = (element: any, data: any, option: any) => {
    option.isChecked = !option.isChecked;
    data.options.filter((x: any) => x.options !== option.options).forEach((y: any) => (y.isChecked = false));
    setSelectedVacScrData((x: any) => x.map((i: any) => (i.screening_id === element.screening_id ? element : i)));
  };

  const openDD = (name: string) => {
    setddOpen(!ddOpen);
    setSelectedDropdown(name);
  };

  const [slot, setSlot] = useState<any>("");
  const [date, setDate] = useState<any>("");
  const [loc, setLoc] = useState<any>("");

  useEffect(() => {
    const getData = async () => {
      const searchParams = new URLSearchParams(location.search);
      let schedule_appointment_id = searchParams.get("sa");
      let slot = searchParams.get("t");
      let date = searchParams.get("date");
      let loc = searchParams.get("loc");
      setScheduleAppointmentId(schedule_appointment_id);
      setSlot(slot);
      setDate(date);
      setLoc(loc);

      let data = {
        vaccine_id: searchParams.get("v"),
        biz_branch_id: searchParams.get("biz_branch_id"),
      };

      const res = await getVaccineScreeningData(data);
      setSelectedVacScrData(res.data.screeningQuestions);
      setSelectedVacData(res.data);
      setSteps(res.data.screeningQuestions.length > 0 ? ["Screening Questions", "Consent Form"] : ["Consent Form"]);
    };
    getData();
    // If selectedVacScrData has items, set activeStep to 0; otherwise, set it to the next step.
    setSteps(selectedVacScrData.length > 0 ? ["Screening Questions", "Consent Form"] : ["Consent Form"]);
    // setActiveStep(selectedVacScrData.length > 0 ? 0 : 1);
  }, [location, setScheduleAppointmentId, setSlot, setDate, setLoc, selectedVacScrData, setSelectedVacScrData, setSelectedVacData, setSteps]);
  return (
    <React.Fragment>
      <DashboardLayout>
        {steps.length > 1 ? (
          <div>
            <div className="flex items-center gap-[5px] cursor-pointer mb-[8px]">
              <Back />
              {activeStep === 0 ? (
                <Link to="/home" className="text-[16px] font-[600] text-[#888A8C]">
                  Back
                </Link>
              ) : activeStep === 1 ? (
                <Link to="#" className="text-[16px] font-[600] text-[#888A8C]" onClick={() => setActiveStep(0)}>
                  Back
                </Link>
              ) : (
                <Link to="#" className="text-[16px] font-[600] text-[#888A8C]" onClick={() => setActiveStep(1)}>
                  Back
                </Link>
              )}
            </div>
            <div className="flex items-center justify-between pt-[0px] py-[20px]">
              <h2 className="text-[24px] text-primary-main font-[600]">Pre-appointment Paperwork</h2>
            </div>
            <Card style={{ padding: "20px" }}>
              <CustomStepper activeStep={activeStep} steps={steps} />
            </Card>
            <br />

            {activeStep === 0 ? (
              <React.Fragment>
                <div className="mt-[24px]" style={{ marginTop: "20px" }}>
                  {selectedVacScrData?.map((element: any, index: number) => (
                    <div className="items-center justify-between pb-[9px]" key={element.screening_id}>
                      <div onClick={() => openDD(element.screening_name)} className="flex items-center justify-between cursor-pointer pb-[9px] border-b-[1px] border-[#878a8c] py-[8px]">
                        <h2 className="text-[18px] font-[600] text-primary-main">{element.screening_name}</h2>
                        <div className="flex gap-[10px] items-center cursor-pointer">
                          <span>
                            <AiFillCaretDown />
                          </span>
                        </div>
                      </div>
                      {ddOpen && selectedDropdown === element.screening_name ? (
                        <div className={`transition-all`}>
                          {element.screening_questions?.map((data: any) => (
                            <div className="border-b-[1px] border-[#9a9ea1] py-[8px]">
                              <p className="text-[14px] font-[400] text-primary-main">{data.screening_Questions_List.questions}</p>
                              <div className="flex flex-col gap-[8px] md:gap-[80px] md:flex-row">
                                {data.options?.map((option: any) => (
                                  <>
                                    <div key={option} className="flex items-center ">
                                      <span key={option} className="text-[#1F2124] text-[14px] font-[400]">
                                        <ol>
                                          <li>
                                            <Checkbox checked={option.isChecked} onChange={() => onCheckBoxClick(element, data, option)} color="success" />
                                            {option.options}
                                          </li>
                                        </ol>
                                      </span>
                                    </div>
                                  </>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
                <div className="mt-[40px] flex grid grid-cols-2 mb-[20px]">
                  <VAButton text="Save and Continue" dark onClick={handleStepOne} />
                </div>
              </React.Fragment>
            ) : activeStep === 1 ? (
              <React.Fragment>
                <div className="flex flex-col gap-[15px]">
                  <FormInput name="firstName" label="Legal First Name" type="text" value={userData.first_name} readOnly />

                  <FormInput name="lastName" label="Legal Last Name" type="text" value={userData.last_name} readOnly />
                  <VADatePicker label="Date of Signature" name="dateOfSignature" value={dateOfSignature} setValue={setDateOfSignature} required onChange={onChange} />
                  <p style={{ textAlign: "justify", fontSize: "0.8rem" }}>{selectedVacData !== undefined ? selectedVacData.vaccine_consent_text : null}</p>

                  <p>
                    <Checkbox checked={checkedConsentTerms} onChange={handleChange} inputProps={{ "aria-label": "controlled" }} color="success" />I agree to the terms and conditions.
                  </p>

                  <FormInput name="signature" label="Patient Signature" type="text" variant="standard" cursive value={signature} required onChange={onChange} />
                </div>
                <div className="mt-[40px] flex grid grid-cols-2 mb-[20px]">
                  <VAButton text="Continue and Submit" dark onClick={handleFinalStep} />
                </div>
              </React.Fragment>
            ) : null}
          </div>
        ) : (
          <div>
            <div className="flex items-center gap-[5px] cursor-pointer mb-[8px]">
              <Back />
              <Link to="/home" className="text-[16px] font-[600] text-[#888A8C]">
                Back
              </Link>
            </div>
            <div className="flex items-center justify-between pt-[0px] py-[20px]">
              <h2 className="text-[24px] text-primary-main font-[600]">Pre-appointment Paperwork</h2>
            </div>
            <Card style={{ padding: "20px" }}>
              <CustomStepper activeStep={activeStep} steps={steps} />
            </Card>
            <br />

            <div className="flex flex-col gap-[15px]">
              <FormInput name="firstName" label="Legal First Name" type="text" value={userData.first_name} readOnly />

              <FormInput name="lastName" label="Legal Last Name" type="text" value={userData.last_name} readOnly />
              <VADatePicker label="Date of Signature" name="dateOfSignature" value={dateOfSignature} setValue={setDateOfSignature} required onChange={onChange} />
              <p style={{ textAlign: "justify", fontSize: "0.8rem" }}>{selectedVacData !== undefined ? selectedVacData.vaccine_consent_text : null}</p>

              <p>
                <Checkbox checked={checkedConsentTerms} onChange={handleChange} inputProps={{ "aria-label": "controlled" }} color="success" />I agree to the terms and conditions.
              </p>

              <FormInput name="signature" label="Patient Signature" type="text" variant="standard" cursive value={signature} required onChange={onChange} />
            </div>
            <div className="mt-[40px] flex grid grid-cols-2 mb-[20px]">
              <VAButton text="Continue and Submit" dark onClick={handleFinalStep} />
            </div>
          </div>
        )}
      </DashboardLayout>
      <PrePaperWorkModal open={open} setOpen={setOpen} slot={slot} date={date} loc={loc} />
      {alertMessage !== null ? <VAAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} /> : null}
    </React.Fragment>
  );
};
