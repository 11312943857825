import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { VAButton } from "../../components";
import { AppState } from "../../redux/store";
export const Welcome = (): ReactElement => {
  const userData = useSelector<AppState, AppState["Login"]>(({ Login }) => Login);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/home");
  };

  return (
    <div className="flex gap-[40px] w-full">
      <div className="hidden md:block md:w-[55%] md:relative ">
        <div className="w-full h-[100vh]">
          <img src={`${process.env.PUBLIC_URL}/imgs/left-side-img.png`} alt="Vaccifi" className="fixed w-[55%] h-full" />
        </div>
      </div>
      <div className="w-full p-[20px]  flex justify-center items-center md:w-[45%]">
        <div className="w-[380px]">
          <div>
            <>
              <div>
                <img src={`${process.env.PUBLIC_URL}/imgs/welcome-img.svg`} alt="vaccine text" className="w-[200px] h-[200px] object-fit: cover" />
              </div>
              <h2 className="text-[34px] font-[600] text-[#0F5175] mt-[12px] mb-[30px]">Welcome back {userData.userData.name}!</h2>
            </>
          </div>
          <div>
            <VAButton text="Get Started" dark onClick={handleClick} />
          </div>
        </div>
      </div>
    </div>
  );
};
