import Divider from "@mui/material/Divider";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";

import { AddVaccineAndPaperwork, CreateSchedules, KeyPassword, Logout, PendingVaccines, ProfileUser, Setting, ViewSchedule } from "../../icons";
import { useLocation, useNavigate } from "react-router-dom";

const allLinks = [
  {
    title: "Home",
    link: "/home",
    icon: <ViewSchedule />,
  },
  {
    title: "Schedule Vaccines",
    link: "/schedule-vaccines",
    icon: <PendingVaccines />,
  },
  {
    title: "Schedule Tests",
    link: "/schedule-tests",
    icon: <PendingVaccines />,
  },
  {
    title: "Vaccine Records",
    link: "/vaccine-records",
    icon: <ProfileUser />,
  },
  {
    title: "Test Records",
    link: "/test-records",
    icon: <ProfileUser />,
  },
  {
    title: "Profile",
    link: "/profile",
    icon: <AddVaccineAndPaperwork />,
  },
  {
    title: "Notifications",
    link: "/notification",
    icon: <CreateSchedules />,
  },

  {
    title: "Setting",
    link: "#",
    icon: <Setting />,
    subLinks: [
      {
        title: "Change Password",
        link: "/change-password",
        icon: <KeyPassword />,
      },
      {
        title: "Logout",
        link: "/",
        icon: <Logout />,
      },
    ],
  },
];

export default function IconMenu() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <MenuList>
      {allLinks?.map((linkMember: any) => {
        if (linkMember?.subLinks) {
          return (
            <>
              <Divider />
              <MenuItem>
                <div key={linkMember.title} className="flex gap-[12px] py-[8px]">
                  <span className={` ${location.pathname === linkMember.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{linkMember?.icon}</span>
                  <span className={` ${location.pathname === linkMember.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{linkMember?.title}</span>
                </div>
              </MenuItem>
              {linkMember?.subLinks?.map((link: any) => {
                return (
                  <MenuItem
                    onClick={() => {
                      navigate(link.link);
                    }}
                  >
                    <div key={link.title} className="flex gap-[12px] px-[28px] pt-[8px]">
                      <span className={` ${location.pathname === linkMember.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{link?.icon}</span>
                      <span className={` ${location.pathname === linkMember.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{link?.title}</span>
                    </div>
                  </MenuItem>
                );
              })}
            </>
          );
        }
        return (
          <MenuItem
            onClick={() => {
              navigate(linkMember.link);
            }}
          >
            <div className="flex gap-[12px] pt-[8px]">
              <span className={` ${location.pathname === linkMember.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{linkMember?.icon}</span>
              <span className={` ${location.pathname === linkMember.link ? "text-[#ffffff]" : "text-[rgba(255,255,255,0.6)]"}`}>{linkMember?.title}</span>
            </div>
          </MenuItem>
        );
      })}
    </MenuList>
  );
}
