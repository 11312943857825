import React, { Fragment } from "react";
import ReactFileViewer from "react-file-viewer";

interface FileViewerProps {
  filePath: any;
  fileType: "pdf" | "csv" | "xslx" | "docx" | "png" | "jpeg" | "gif" | "bmp" | "mp4" | "webm" | "mp3";
}
const FileViewer: React.FC<FileViewerProps> = ({ filePath, fileType }: FileViewerProps) => {
  const onError = (e: any) => {
    console.log(e, "error in file-viewer");
  };
  console.log(filePath);
  return <Fragment>{filePath && <ReactFileViewer fileType={fileType} filePath={filePath} onError={onError} />}</Fragment>;
};

export default FileViewer;
