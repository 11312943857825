import { ReactElement, useEffect, useState } from "react";
import "./TestRecords.css";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/store";
import { getShareTestRecords } from "../../redux/actionCreators";
import { DashboardLayout } from "../../components";
import InjectionIcon from "../../icons/InjectionIcon.icon";
import { TestRecord } from "../../sections/TestRecord";

export const TestRecords = (): ReactElement => {
  const { userData } = useSelector<AppState, AppState["Login"]>(({ Login }) => Login);
  const [selectedTabValue, setSelectedTabValue] = useState(0);
  const [shareTestRecordsVerified, setShareTestRecordsVerified] = useState([]);
  const [shareTestRecordsUnverified, setShareTestRecordsUnverified] = useState([]);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabValue(newValue);
  };
  useEffect(() => {
    if (!userData?.email) return;
    (async () => {
      try {
        const res = await getShareTestRecords({ email: userData.email });
        setShareTestRecordsVerified(res?.shareTestRecordsVerified);
        setShareTestRecordsUnverified(res?.shareTestRecordsUnverified);
      } catch (error) {
        console.error("Error in getShareTestRecords:", error);
      }
    })();
  }, [userData, setShareTestRecordsVerified, setShareTestRecordsUnverified]);
  return (
    <DashboardLayout>
      <div className="flex items-center justify-between mb-[5px]">
        <h2 className="text-[24px] text-primary-main font-[600]">{"Your Test Card"}</h2>
      </div>
      <div className="view-vaccine-card_">
        <div className="Card">
          <div className="Rectangle-133">
            <span className="Mark-Grammy">{userData.name}</span>
            <br />
            <span className="Nov-13-1991">{userData.email}</span>
            {/* <span className="Nov-13-1991">Nov 13, 1991</span> */}
            <div className="Mask-Group">
              <div className="Group-338">
                <div className="Vector">
                  <InjectionIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
        <TestRecord selectedTabValue={selectedTabValue} handleChangeTab={handleChangeTab} shareTestRecordsVerified={shareTestRecordsVerified} shareTestRecordsUnverified={shareTestRecordsUnverified} />
      </div>
    </DashboardLayout>
  );
};
