import { ReactElement, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormFieldsHandleChange } from "../../utils";
import { LoginForm } from "../../sections/Login";
import { onSubmitLogin } from "../../redux/actionCreators/Login";
import { useDispatch } from "react-redux";
import { SET_USER_DATA } from "../../types/actions/Login";
import { LoginModel } from "../../types/models/Login";

export const Login = (): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [input] = useFormFieldsHandleChange<LoginModel>({ email: "", password: "" });
  const [loginInputs, handleLoginInfoChange] = useFormFieldsHandleChange(input);
  const [errorsLoginInputs, setErrorsLoginInfo] = useState({ email: { error: false, message: "" }, password: { error: false, message: "" } });
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const validateErrorLoginInfo = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!e.target.value) {
      setErrorsLoginInfo({ ...errorsLoginInputs, [e.target.name]: { error: true, message: "" } });
    } else {
      setErrorsLoginInfo({ ...errorsLoginInputs, [e.target.name]: { error: false, message: "" } });
    }
  };

  const validateFormLoginInfo = () => {
    let formIsValid = true;
    let localerrorsLoginInputs = errorsLoginInputs;

    if (loginInputs.email.trim() === "") {
      formIsValid = false;
      localerrorsLoginInputs.email = { error: true, message: "Email is Required!" };
    } else if (!emailPattern.test(loginInputs.email.trim())) {
      formIsValid = false;
      localerrorsLoginInputs.email = { error: true, message: "Invalid Email!" };
    }

    if (loginInputs.password.trim() === "") {
      formIsValid = false;
      localerrorsLoginInputs.password = { error: true, message: "Password is Required!" };
    }

    setErrorsLoginInfo({ ...errorsLoginInputs, ...localerrorsLoginInputs });
    return formIsValid;
  };

  const handleSubmit = async () => {
    if (!validateFormLoginInfo()) return;
    let res = await onSubmitLogin(loginInputs);

    dispatch({ type: SET_USER_DATA, payload: res.userData });

    if (res.data === "Wrong Credentials") alert("wrong Credentials");
    else {
      navigate("/welcome");
    }
  };

  return (
    <div className="flex gap-[40px] w-full">
      <div className="hidden md:block md:w-[55%] md:relative ">
        <div className="w-full h-[100vh]">
          <img src={`${process.env.PUBLIC_URL}/imgs/left-side-img.png`} alt="Vaccifi" className="fixed w-[55%] h-full" />
        </div>
      </div>
      <div className="w-full p-[20px]  flex justify-center items-center md:w-[45%]">
        <div className="w-[380px]">
          <div>
            <div>
              <img src={`${process.env.PUBLIC_URL}/imgs/va-logo-with-text.svg`} alt="vaccine text" />
            </div>
            <h2 className="text-[25px] font-[600] text-[#0F5175]">Sign In</h2>
          </div>
          <div className="mt-[30px]">
            <LoginForm inputs={loginInputs} errorsLoginInputs={errorsLoginInputs} validateErrorLoginInfo={validateErrorLoginInfo} onChange={handleLoginInfoChange} handleSubmit={handleSubmit} />
          </div>

          <div style={{ textAlign: "center" }} className="mt-[40px]">
            <span className="text-[16px] font-[400] text-[#888A8C]">
              <Link to="/signup" className="text-[16px] text-[#0F5175] font-[600px]">
                Create a New Account
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
