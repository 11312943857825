import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import FileViewer from "react-file-viewer";

interface PDFViewerProps {
  filePath?: any;
}
const PDFViewer: React.FC<PDFViewerProps> = ({ filePath }: PDFViewerProps) => {
  const [pdfURL, setPdfURL] = useState<string | null>(null);

  useEffect(() => {
    // Fetch the PDF file from the backend (replace with your API endpoint)
    axios
      .post(`${process.env.REACT_APP_API_URL}scheduleRouter/getprovvaccincevis`, { filePath }, { responseType: "blob" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        setPdfURL(url);
      })
      .catch((error) => {
        console.error("Error fetching PDF:", error);
      });
  }, []);

  const onError = (e: any) => {
    console.log(e, "error in file-viewer"); //process.env.REACT_APP_DOCUMENT_URL + "Vaccifi Terms and Conditions.pdf"
  };
  return <Fragment>{pdfURL && <FileViewer fileType={"pdf"} filePath={pdfURL} onLoad={() => console.log("PDF loaded successfully")} onError={onError} />}</Fragment>;
};

export default PDFViewer;
