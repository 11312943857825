import { PatientInfoModel } from "../models/Patient";

export const SIGN_UP = "SIGN_UP";

export interface SignupAction {
  type: typeof SIGN_UP;
  payload: PatientInfoModel;
}

export type SignupActionTypes = SignupAction;
