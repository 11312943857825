import { ReactElement } from "react";

export const VADigitalCard = (): ReactElement => {
  return (
    <svg width="43" height="41" viewBox="0 0 43 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.869 25.3192H3.7069C2.72377 25.3192 1.7809 24.9287 1.08572 24.2335C0.390547 23.5383 0 22.5954 0 21.6123V3.8192C0 1.77194 1.65963 0.112305 3.7069 0.112305H33.3621C35.4093 0.112305 37.069 1.77194 37.069 3.8192V18.7506L19.869 25.3192Z"
        fill="#FFB547"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.67255 21.6124H5.18979C4.3719 21.61 3.70947 20.9476 3.70703 20.1297V5.30209C3.70947 4.4842 4.3719 3.82178 5.18979 3.81934H17.0519C17.8697 3.82178 18.5322 4.4842 18.5346 5.30209V20.1297C18.5322 20.9476 17.8697 21.61 17.0519 21.6124H6.67255Z"
        fill="#E3FAFF"
      />
      <path
        d="M11.121 14.1985C13.1682 14.1985 14.8279 12.5388 14.8279 10.4916C14.8279 8.4443 13.1682 6.78467 11.121 6.78467C9.0737 6.78467 7.41406 8.4443 7.41406 10.4916C7.41406 12.5388 9.0737 14.1985 11.121 14.1985Z"
        fill="#FDD7AD"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5684 18.647V21.6125H6.67188V18.647C6.67188 16.1903 8.66344 14.1987 11.1202 14.1987C13.5769 14.1987 15.5684 16.1903 15.5684 18.647Z"
        fill="#FC476E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3792 19.3884V21.6125H8.89648V19.3884C8.89648 18.9789 9.22841 18.647 9.63786 18.647C10.0473 18.647 10.3792 18.9789 10.3792 19.3884Z"
        fill="#CC2B5A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3441 19.3884V21.6125H11.8613V19.3884C11.8613 18.9789 12.1933 18.647 12.6027 18.647C13.0122 18.647 13.3441 18.9789 13.3441 19.3884Z"
        fill="#CC2B5A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.3621 6.0433H22.2414C21.8319 6.0433 21.5 5.71138 21.5 5.30193C21.5 4.89247 21.8319 4.56055 22.2414 4.56055H33.3621C33.7715 4.56055 34.1034 4.89247 34.1034 5.30193C34.1034 5.71138 33.7715 6.0433 33.3621 6.0433Z"
        fill="#E3FAFF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.9138 9.00864H22.2414C21.8319 9.00864 21.5 8.67671 21.5 8.26726C21.5 7.85781 21.8319 7.52588 22.2414 7.52588H28.9138C29.3232 7.52588 29.6552 7.85781 29.6552 8.26726C29.6552 8.67671 29.3232 9.00864 28.9138 9.00864Z"
        fill="#E3FAFF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M33.3621 11.9745H22.2414C21.8319 11.9745 21.5 11.6425 21.5 11.2331C21.5 10.8236 21.8319 10.4917 22.2414 10.4917H33.3621C33.7715 10.4917 34.1034 10.8236 34.1034 11.2331C34.1034 11.6425 33.7715 11.9745 33.3621 11.9745Z"
        fill="#E3FAFF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.9138 14.9398H22.2414C21.8319 14.9398 21.5 14.6079 21.5 14.1984C21.5 13.789 21.8319 13.457 22.2414 13.457H28.9138C29.3232 13.457 29.6552 13.789 29.6552 14.1984C29.6552 14.6079 29.3232 14.9398 28.9138 14.9398Z"
        fill="#E3FAFF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M42.9997 29.0261C42.9969 34.3914 39.3914 39.0861 34.2085 40.4731C29.0256 41.8601 23.5573 39.5937 20.8751 34.9469C18.193 30.3001 18.9657 24.4313 22.7592 20.6372C26.5527 16.843 32.4214 16.0693 37.0686 18.7506C40.7396 20.87 43.0006 24.7872 42.9997 29.0261Z"
        fill="#4FBA6F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.4341 34.9573C27.2375 34.9573 27.049 34.8792 26.91 34.7401L23.9444 31.7746C23.6635 31.4837 23.6675 31.0213 23.9535 30.7353C24.2394 30.4493 24.7019 30.4453 24.9928 30.7263L27.4734 33.2062L37.3338 24.7545C37.5332 24.5702 37.8174 24.5097 38.0746 24.5968C38.3318 24.6839 38.5208 24.9046 38.5673 25.1721C38.6138 25.4396 38.5103 25.7112 38.2975 25.8799L27.9182 34.7764C27.784 34.8932 27.612 34.9575 27.4341 34.9573Z"
        fill="white"
      />
    </svg>
  );
};
