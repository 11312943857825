import { Fragment, ReactElement } from "react";
import VaccineRecordTabs from "./Tabs";
interface VaccineRecordProps {
  selectedTabValue: number;
  handleChangeTab: any;
  shareVaccineRecordsVerified: any;
  shareVaccineRecordsUnverified: any;
}
export const VaccineRecord = ({ selectedTabValue, handleChangeTab, shareVaccineRecordsVerified, shareVaccineRecordsUnverified }: VaccineRecordProps): ReactElement => {
  return (
    <Fragment>
      <VaccineRecordTabs
        value={selectedTabValue}
        handleChange={handleChangeTab}
        shareVaccineRecordsVerified={shareVaccineRecordsVerified}
        shareVaccineRecordsUnverified={shareVaccineRecordsUnverified}
      />
    </Fragment>
  );
};
