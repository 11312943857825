import { ReactElement } from "react";
import { VAButton, FormInput } from "../../components";
interface forgetPassProps {
  inputs: any;
  errorsforgotPassInfo: any;
  validateErrorforgotPassInfo: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onSubmit: any;
}

export const ForgotPasswordForm = ({ inputs, errorsforgotPassInfo: errors, validateErrorforgotPassInfo: validateError, onChange, onSubmit }: forgetPassProps): ReactElement => {
  return (
    <>
      <div className="flex flex-col gap-[30px]">
        <FormInput
          name="email"
          label="Email"
          type="email"
          value={inputs.email}
          validate={{ error: errors.email.error, message: errors.email.message }}
          required
          onChange={onChange}
          onBlur={validateError}
        />
        <div>
          <VAButton text="Send Reset Password Link" onClick={onSubmit} dark />
        </div>
      </div>
    </>
  );
};
