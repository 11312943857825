import { ReactElement, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { checkEmail } from "../../redux/actionCreators/signup";
import { SignUpForm } from "../../sections/SignUp";
import { PatientInfoModel } from "../../types/models/Patient";
import { useFormFieldsHandleChange } from "../../utils";
import emailjs from "@emailjs/browser";
import { Back } from "../../icons";
import { SET_USER_DATA } from "../../types/actions/Login";
import { AuthenticationModal } from "../../components";

export const SignUp = (): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  // Patient State and Handlers
  const [verificationCode, setVCode] = useState(0);
  const [patientInfo] = useState<PatientInfoModel>({ email: "", password: "", confirm_password: "", ConditionCheck: false, verificationCode: 0 });
  const [patientInfoInputs, handlePatientInfoChange] = useFormFieldsHandleChange(patientInfo);
  const [errorsPatientInfo, setErrorsPatientInfo] = useState({
    email: { error: false, message: "" },
    password: { error: false, message: "" },
    confirm_password: { error: false, message: "" },
    ConditionCheck: { error: false, message: "" },
    verificationCode: { error: false, message: "" },
  });

  const validateErrorPatientInfo = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!e.target.value) {
      setErrorsPatientInfo({ ...errorsPatientInfo, [e.target.name]: { error: true, message: "Required Field!" } });
    } else {
      setErrorsPatientInfo({ ...errorsPatientInfo, [e.target.name]: { error: false, message: "" } });
    }
  };

  const validateFormPatientInfo = () => {
    let formIsValid = true;
    let localerrorsPatientInfo = errorsPatientInfo;

    if (patientInfoInputs.email === "") {
      formIsValid = false;
      localerrorsPatientInfo.email = { error: true, message: "Required Field!" };
    } else if (!emailPattern.test(patientInfoInputs.email)) {
      formIsValid = false;
      localerrorsPatientInfo.email = { error: true, message: "Invalid Email!" };
    }

    if (patientInfoInputs.password === "") {
      formIsValid = false;
      localerrorsPatientInfo.password = { error: true, message: "Required Field!" };
    }

    if (patientInfoInputs.confirm_password === "") {
      formIsValid = false;
      localerrorsPatientInfo.confirm_password = { error: true, message: "Required Field!" };
    }

    if (patientInfoInputs.password !== patientInfoInputs.confirm_password) {
      formIsValid = false;
      localerrorsPatientInfo.password = { error: true, message: "Password should be same as Confirm Password!" };
      localerrorsPatientInfo.confirm_password = { error: true, message: "Confirm Password should be same as Password!" };
    }

    setErrorsPatientInfo({ ...errorsPatientInfo, ...localerrorsPatientInfo });
    return formIsValid;
  };

  const sendEmail = () => {
    let code = Math.floor(Math.random() * 900000) + 100000;
    setVCode(code);
    const values = {
      email: patientInfoInputs.email,
      role: "",
      message: `Your Verification code is ${code}`,
    };
    emailjs.send("service_q1sj92a", "template_fgbr8ag", values, "bPnkTyXgeUXVCp7PY");
  };

  const onSubmitSignUp = async () => {
    if (!validateFormPatientInfo()) return;
    let res = await checkEmail(patientInfoInputs.email);
    if (res.message === "Email Already Exist") {
      alert("Email Already Exist");
      return;
    } else {
      sendEmail();
      setOpen(true);
    }
  };

  const onSubmitVerificationCode = async () => {
    if (patientInfoInputs.verificationCode.toString() === verificationCode.toString()) {
      dispatch({ type: SET_USER_DATA, payload: patientInfoInputs });
      navigate("/terms-condition");
    }
  };

  const onClickResendCode = () => {
    sendEmail();
  };

  return (
    <div className="flex gap-[40px] w-full">
      <div className="hidden md:block md:w-[55%] md:relative ">
        <div className="w-full h-[100vh]">
          <img src={`${process.env.PUBLIC_URL}/imgs/left-side-img.png`} alt="Vaccifi" className="fixed w-[55%] h-full" />
        </div>
      </div>
      <div className="w-full p-[20px]  flex justify-center items-center md:w-[45%]">
        <div className="w-[380px]">
          <div className="flex items-center gap-[5px] cursor-pointer">
            <Back />
            <Link to="/" className="text-[16px] font-[600] text-[#888A8C]">
              Back
            </Link>
          </div>
          <div>
            <h2 className="text-[30px] font-[600] text-[#0F5175]">Sign Up</h2>
          </div>
          <div className="mt-[30px]">
            <SignUpForm
              inputs={patientInfoInputs}
              errorsPatientInfo={errorsPatientInfo}
              validateErrorPatientInfo={validateErrorPatientInfo}
              onChange={handlePatientInfoChange}
              onSubmitSignUp={onSubmitSignUp}
              // onSubmitVerificationCode={onSubmitVerificationCode}
              // onClickResendCode={onClickResendCode}
            />
          </div>

          <div className="mt-[40px] text-center">
            <Link to="/" className="text-[16px] text-[#0F5175] font-[400px] underline">
              Already have an account?
            </Link>
          </div>
          <AuthenticationModal open={open} handleClose={handleClose} patientInfoInputs={patientInfoInputs} onSubmitVerificationCode={onSubmitVerificationCode} onClickResendCode={onClickResendCode} />
        </div>
      </div>
    </div>
  );
};
