import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel, { stepLabelClasses } from "@mui/material/StepLabel";
import { Notification, BookAppoinment, VADigitalCard } from "../../icons";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
const QontoConnector = styled(StepConnector)(() => ({
  [`& .${stepConnectorClasses.line}`]: {
    marginLeft: 9,
  },
  [`& .${stepLabelClasses.completed}`]: {
    color: "#0F5175",
  },
}));

const steps = [
  { label: "Get notifications you're due for vaccines" },
  { label: "Seamlessly book appointment at nearby provider convenient for you" },
  { label: "Maintain digital vaccine records in one place" },
];
export const VerticalStepper = () => {
  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper orientation="vertical" connector={<QontoConnector />}>
        {steps.map((step, index) => (
          <Step key={step.label} completed={true}>
            <StepLabel StepIconComponent={index === 0 ? Notification : index === 1 ? BookAppoinment : VADigitalCard}>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
