import { LoginModel } from "../models/Login";

export const SEND_LOGIN_DATA = "SEND_LOGIN_DATA";
export const SET_USER_DATA = "SET_USER_DATA";

export interface LoginAction {
  type: typeof SEND_LOGIN_DATA;
  payload: LoginModel;
}

export interface userDataAction {
  type: typeof SET_USER_DATA;
  payload: any;
}

export type LoginActionTypes = LoginAction | userDataAction;
