import { ReactElement, useState } from "react";
import { parse } from "date-fns";
import { getEventsByDayNumber, getMatchMonthAndYear } from "../../utils/calendar";
import useDate from "../../hooks/useDate";
import { MonthAndYear } from "../../components/VaccineCalendar/MonthAndYear.component";
import { AllDay } from "../../components/VaccineCalendar/AllDays.component";
import { DayCard } from "../../components/VaccineCalendar/DayCard.component";

interface props {
  selectedDate: any;
  setSelectedDate: any;
  scheduleData: any;
  setOpenPopover: any;
}

export const SelectDateAndTime = ({ selectedDate, setSelectedDate, scheduleData, setOpenPopover }: props): ReactElement => {
  const date = useDate();
  const [events] = useState([]);
  const eventsInSelectedMonth = getMatchMonthAndYear(date.month, date.year, events);
  // An array of days containing events for populating the calendar
  const days = Array.from({ length: date.daysInMonth }, (_, i) => {
    const currentDay: any = i + 1;

    //Creates dateObject using month spelled out in a string, currentDay and year
    const dateObject = parse(`${date.month}, ${currentDay}, ${date.year}`, "MMMM, d, yyyy", new Date());
    return {
      date: dateObject,
      events: getEventsByDayNumber(currentDay, eventsInSelectedMonth),
    };
  });

  // Use for generating empty divs to fill days from previous month
  const DAYS: any = {
    Mon: 0,
    Tue: 1,
    Wed: 2,
    Thu: 3,
    Fri: 4,
    Sat: 5,
    Sun: 6,
  };
  const daysFromPrevMonth = Array.from({ length: DAYS?.[date.firstDay] }, (_, i) => i + 1);

  // Total number of squares in the calendar
  const totalDays = daysFromPrevMonth.length + days.filter((day) => day).length;
  // Number of rows in the calendar
  const numRows = Math.ceil(totalDays / 7);

  return (
    <div className="flex flex-col justify-between">
      <div>
        <div className="overflow-x-scroll w-full md:w-full md:pr-[0px] flex flex-grow h-[500px] md:overflow-auto text-gray-700">
          <div className="flex flex-col flex-grow">
            <MonthAndYear month={date.month} year={date.year} handleNextMonth={date.getNextMonth} handlePreviousMonth={date.getPreviousMonth} />
            <AllDay />
            <div className={`grid flex-grow w-full h-auto grid-cols-7 grid-rows-${numRows} gap-px pt-px  bg-gray-200`}>
              {daysFromPrevMonth.map((day) => (
                <div key={`day-${day}`}></div>
              ))}

              {days
                .filter((day) => day)
                .map((dayData: any) => (
                  <DayCard key={dayData.date} {...dayData} scheduleData={scheduleData} selectedDate={selectedDate} setSelectedDate={setSelectedDate} setOpenPopover={setOpenPopover} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
