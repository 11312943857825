import { ReactElement } from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { PatternFormat } from "react-number-format";

const PatternFormatField = ({ name, inputRef, onChange, onBlur, ...other }: any) => {
  let _format = null,
    _prefix = "+1 ",
    _decimalScale = null,
    _fixedDecimalScale = false;

  if (name === "phone" || name === "ph_no") _format = "(###) ###-####";
  else if (name === "businessZip" || name === "zipcode") _format = "#####";
  return (
    <PatternFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values: any) => {
        onChange({ target: { name, value: values.value } });
      }}
      thousandSeparator
      decimalScale={_decimalScale}
      fixedDecimalScale={_fixedDecimalScale}
      isNumericString
      prefix={_prefix}
      format={_format}
    />
  );
};

interface InputProps {
  name?: string;
  label?: any;
  value?: string;
  placeholder?: string;
  validate?: { error: boolean; message: string };
  required?: boolean;
  type?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const CssTextField = styled(TextField)({
  "&": {
    width: "100%",
  },
  "& input.MuiInputBase-input": {
    paddingLeft: "18px",
  },
  "& label.Mui-focused": {
    color: "#888A8C",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#CDCFD2",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    "& fieldset": {
      borderColor: "#CDCFD2",
    },
    "&:hover fieldset": {
      borderColor: "#CDCFD2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#CDCFD2",
    },
  },
});

export const NumberFormatField = ({ label, name, value, placeholder, validate, required, onChange, onBlur }: InputProps): ReactElement => {
  return (
    <div className="relative w-full">
      <CssTextField
        id="custom-css-outlined-input"
        label={label}
        name={name}
        value={value}
        placeholder={placeholder}
        error={validate?.error}
        helperText={validate?.message}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        InputProps={{
          disableUnderline: true,
          style: { width: "100%" },
          inputComponent: PatternFormatField,
        }}
      />
    </div>
  );
};
